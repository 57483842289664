import React from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalBody, Button, ModalFooter, ModalHeader } from 'reactstrap';
import { useBrand } from '../../../store/site';


const ValidationFailedModal = ({ isOpen, toggle, data }) => {

  const brand = useBrand();

  const errors = Object.keys(data.errors)
    .reduce((acc, key) => acc.concat(data.errors[key]), []);

  return (
    <Modal isOpen={isOpen} toggle={toggle} centered={true} size="md">
      <ModalHeader toggle={toggle}>Oops, something’s not right</ModalHeader>
      <ModalBody>
        <div className="row">
          <div className="col-12">
            <div>Please correct the following issues before continuing …</div>
            <ul>
              {errors.map((e, i) => <li key={i}>{e}</li>)}
            </ul>
            <div className="mt-2">For additional assistance, contact Customer Service at <br /> {brand.phone}</div>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button onClick={() => { toggle(); }}>Ok</Button>
      </ModalFooter>
    </Modal>
  );
}

ValidationFailedModal.propTypes = {
  data: PropTypes.shape({
    errors: PropTypes.object.isRequired
  }).isRequired
};

export default ValidationFailedModal;
