import React from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import { createSalesforceLead } from '../../ajax/lead';
import { FieldGroup, TextField, NumberField, SelectField, TextAreaField } from '../Shared/FormElements';
import { StateOptions } from '../Shared/SelectOptions';
import { COUNTRIES, REGEXES, VALIDATION_MESSAGES } from '../../constants';
import { useLaunchQueryParams } from '../../store/site';
import { useGtmVariables } from '../../store/gtm';

const CUSTOMER_CARE = 'Customer Care';


const sendToLytics = (values) => {
  const firstName = (values.firstName) ? values.firstName : "";
  const lastName = (values.lastName) ? values.lastName : "";
  const email = (values.email) ? values.email : "";
  const phone = (values.phone) ? values.phone : "";
  const state = (values.stateCode) ? values.stateCode : "";
  const company = (values.company) ? values.company : "";
  const zip = (values.zip) ? values.zip : "";

  window.jstag.send({
    "first_name": firstName,
    "last_name": lastName,
    email,
    phone,
    company,
    "headquarters_state": state,
    "headquarters_zip": zip,
    "needs_assessment": new Date().toISOString(),
  })
};

const InnerForm = ({ handleSubmit, isSubmitting, setStatus, values }) => <Form noValidate>
  <div className="row">
    <div className="col-6">
      <FieldGroup name="firstName" label="First Name*" component={TextField} labelClassName="text-small" />
    </div>
    <div className="col-6">
      <FieldGroup name="lastName" label="Last Name*" component={TextField} labelClassName="text-small" />
    </div>
  </div>
  <div className="row">
    <div className="col-6">
      <FieldGroup name="email" label="Email Address*" component={TextField} placeholder="name@example.com" labelClassName="text-small" />
    </div>
    <div className="col-6">
      <FieldGroup name="phone" label="Phone*" format="(###) ###-####" component={NumberField} placeholder="(XXX) XXX-XXXX" labelClassName="text-small" />
    </div>
  </div>
  <div className="row">
    <div className="col-12">
      <FieldGroup name="company" label="Organization Name*" component={TextField} labelClassName="text-small" />
    </div>
  </div>
  <div className="row">
    <div className="col-6">
      <FieldGroup name="stateCode" label="State*" component={SelectField} labelClassName="text-small">
        <option value=""></option>
        <StateOptions country={COUNTRIES.US} />
        <StateOptions country={COUNTRIES.CA} />
      </FieldGroup>
    </div>
    <div className="col-6">
      <FieldGroup name="zip" label="Postal Code*" component={TextField} labelClassName="text-small" />
    </div>
  </div>
  {values.leadSource === CUSTOMER_CARE && <div className="row">
    <div className="col-12">
      <FieldGroup name="description" label="Comments" component={TextAreaField} labelClassName="text-small" />
    </div>
  </div>}
  <button type="button" className="btn btn-primary mt-2 mr-3" disabled={isSubmitting} onClick={() => { handleSubmit(); setStatus('submitted'); }}>Submit</button>
</Form>;

export default ({ onSubmitted }) => {

  const { leadSource } = useLaunchQueryParams();
  const [{ utm_source, utm_campaign, utm_stareq }] = useGtmVariables();

  return <Formik
    enableReinitialize={true}
    validationSchema={Yup.object().shape({
      firstName: Yup.string().trim().required().max(35),
      lastName: Yup.string().trim().required().max(35),
      email: Yup.string().required().email().max(50).trim().lowercase(),
      phone: Yup.string().trim().required().matches(REGEXES.PHONE, VALIDATION_MESSAGES.PHONE),
      company: Yup.string().trim().required().max(50),
      stateCode: Yup.string().required(),
      zip: Yup.string().trim().required().matches(REGEXES.POSTAL_CODE, VALIDATION_MESSAGES.POSTAL_CODE),
      description: Yup.string().trim().max(250)
    })}
    initialValues={{
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      company: '',
      stateCode: '',
      zip: '',
      leadSource: leadSource || 'Web',
      description: leadSource === CUSTOMER_CARE ? '' : document.location.href,
      returnUrl: document.location.href,
      utmSource: utm_source,
      utmCampaign: utm_campaign,
      utmStarEq: utm_stareq
    }}
    component={InnerForm}
    onSubmit={async (values, actions) => {

      try {
        await sendToLytics(values);
        await createSalesforceLead(values);
        actions.resetForm();
        onSubmitted && onSubmitted();
        window.$('#needsModalThanks').modal('show');
      } finally {
        actions.setSubmitting(false);
      }
    }} />
};
