import axios from 'axios';
import { API_URL } from '../constants';

const dataOnly = (response) => response.data;

const createExternalInstance = () => {

  const instance = axios.create();
  instance.interceptors.response.use(dataOnly);
  return instance;
}

export const externalAxios = createExternalInstance();

const createApiInstance = (baseURL) => {

  const instance = axios.create({
    baseURL
  });
  instance.interceptors.response.use((response) => {
    if (response.config.includeStatus) {
      return response;
    }
    return response.data;
  });
  return instance;
}

export default createApiInstance(API_URL);
