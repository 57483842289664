import { useSelector } from 'react-redux';

import { forType } from '../ajax/catalog';
import { CATALOG_TYPES } from '../constants';

import { checkInPerson } from '../ajax/seminar'

const initialState = {
  brand: {},
  country: {}
};

const LOAD_LAUNCH_SEMINAR = 'SITE/LOAD_LAUNCH_SEMINAR';
const HAS_SEMINARS = 'SITE/HAS_SEMINARS';
const SET_LAUNCH = 'SITE/SET_LAUNCH';

export const actionCreators = {
  loadLaunchSeminar: (id) => async (dispatch, getState) => {

    const { search: { lat, lng } } = getState();

    const results = await forType(CATALOG_TYPES.SEMINAR, { lat, lng, id });

    if (results.items.length > 0) {
      const topic = results.items[0].topics[0];
      const seminar = topic.seminars[0];

      dispatch({
        type: LOAD_LAUNCH_SEMINAR, seminar: {
          topicId: topic.topicId,
          ...seminar
        }
      });
    }
  },
  setHasSeminars: (search) => async (dispatch) => {
    var hasSeminars = await checkInPerson(search);
    dispatch({
      type: HAS_SEMINARS,
      hasSeminars
    });
  },
  setLaunch: (launchQueryParams) => async (dispatch) => {

    dispatch({
      type: SET_LAUNCH, launchQueryParams: launchQueryParams.launchQueryParams
      
    });
  }
};

export const reducer = (state, action) => {
  state = state || initialState;

  if (action.type === LOAD_LAUNCH_SEMINAR) {
    return {
      ...state,
      launchQueryParams: {
        ...state.launchQueryParams,
        seminar: {
          ...action.seminar
        }
      }
    }
  }

  if (action.type === HAS_SEMINARS) {
    return {
      ...state,
      hasSeminars: action.hasSeminars
    }
  }

  if (action.type === SET_LAUNCH) {
    return {
      ...state,
      launchQueryParams: 
        action.launchQueryParams
      }
    }

  return state;
};

export const useSite = () => {

  const site = useSelector(state => state.site);
  return site;
}

export const useLaunchQueryParams = () => {

  const { launchQueryParams } = useSelector(state => state.site);
  return launchQueryParams;
}

export const useBrand = () => {

  const { brand } = useSelector(state => state.site);
  return brand;
}

export const useCountry = () => {

  const { country } = useSelector(state => state.site);
  return country;
}

export const useHasSeminars = () => {

  const { hasSeminars } = useSelector(state => state.site);
  return hasSeminars;
}
