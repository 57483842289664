import React from 'react';

import SecondaryNavigation from './Shared/SecondaryNavigation';
import PrimaryNavigation from './Shared/PrimaryNavigation';
import SideNavigation from './Shared/SideNavigation';
import { useToggle } from '../utils/hooks';

const Overlay = (props) => (<div className="Overlay" onClick={props.onClick} />);

export default () => {

  const [sideNavOpen, toggleSideNav] = useToggle();

  return (
    <header className="bg-white">

      <a href="#mainContent" className="ada-btn btn btn-primary w-100" style={{borderRadius:'0'}}>Skip to main content</a>
      <SecondaryNavigation onToggle={toggleSideNav} />
      <PrimaryNavigation onToggle={toggleSideNav} />

      <SideNavigation open={sideNavOpen} onToggle={toggleSideNav} />
      {sideNavOpen && <Overlay onClick={toggleSideNav} />}
      
    </header>
  );
}

