import React from 'react';
import PropTypes from 'prop-types';
import { Form, Field, Formik } from 'formik';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import classnames from 'classnames';

import { REGEXES } from '../../constants';
import * as Search from '../../store/search';

const InnerForm = ({
  isSubmitting,
  placeholder,
  showOutlineBtn,
  showPrimaryBtn,
  btnId,
  outlineBtnColor = 'btn-outline-secondary'
}) => (
    <Form className="GenericSearchBar" role="search">
      <div className="input-group">
        <Field type="text" name="term" className="form-control" placeholder={placeholder || 'Search by keyword or postal code'} aria-labelledby={btnId || "searchBtn"} />
        <div className="input-group-append">
          {showOutlineBtn &&
            <button type="submit" className={classnames('btn', outlineBtnColor)} disabled={isSubmitting} id={btnId || "searchBtn"}>
              Search
            </button>
          }
          {showPrimaryBtn &&
            <button type="submit" className="btn btn-primary" disabled={isSubmitting} aria-label="Search">
              <i className="fas fa-search"></i>
            </button>
          }
        </div>
      </div>
    </Form>
  );

InnerForm.propTypes = {
  placeholder: PropTypes.string,
  showOutlineBtn: PropTypes.bool,
  showPrimaryBtn: PropTypes.bool
};

const getInitialValues = (search) => ({
  term: search.term || ''
});

export default (props) => {

  const search = Search.useSearch();
  const dispatch = useDispatch();

  return <Formik
    enableReinitialize
    initialValues={getInitialValues(search)}
    onSubmit={(values, { setSubmitting }) => {
      try {

        // if a US or CA postal code
        // https://stackoverflow.com/a/46761018/199260
        const criteria = REGEXES.POSTAL_CODE.test(values.term)
          ? { postalCode: values.term }
          : { ...search, ...values };

        dispatch(Search.actionCreators.set(criteria));
        dispatch(push('/search'));
      }
      finally {
        setSubmitting(false);
      }
    }}
  >
    {(formProps) => <InnerForm {...formProps} {...props} />}
  </Formik>
};

