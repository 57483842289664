import axios from './handler';

export const pageContentForRoute = route => {

  return axios.get(`/content/route${route}`);
};

export const pageContentForType = type => {

  return axios.get(`/content/type/${type}`);
};

export const fragmentContent = (type, instance) => {

  return axios.get(`/content/fragment/${type}/${instance || ''}`);
};


