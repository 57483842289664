import React from 'react';
import { Helmet } from 'react-helmet';
import OnsiteHeader from './OnsiteHeader';
import OnsiteNeedsAssessmentForm from './OnsiteNeedsAssessmentForm';

export default ({ notFound = false }) => {

  const content = notFound
    ? {
      heading: 'Thanks for visiting!',
      subHeading: 'The page you’ve requested is no longer available, but SkillPath Enterprise Solutions is. Whatever challenges you face, we’ll help you find a training solution that works for your organization.'
    }
    : {
      heading: 'Contact Us',
      subHeading: 'Whatever unique challenges you face, Enterprise Solutions can meet your organization’s learning and development needs'
    };

  return <>
    <Helmet>
      <title>On-Site Contact Us</title>
    </Helmet>

    <div className="container-fluid position-relative p-0">
      <OnsiteHeader>
        <h1 className="text-extrathin mb-3 text-white"><span className="text-semibold">{content.heading}</span></h1>
        <p className="text-white mb-4 pr-xl-5">{content.subHeading}</p>
      </OnsiteHeader>
    </div>

    <div className="container-fluid bg-white angle z-index-1 position-relative px-0">
      <div className="container pt-5 pb-5">
        <div className="row d-flex justify-content-between pb-5">
          <div className="col-md-7">
            <h2 className="text-primary h3">Want to learn more about training your group?</h2>
            <p>Just complete the information below and we will be in touch soon.</p>
            <OnsiteNeedsAssessmentForm />
          </div>
          <div className="col-md-4 pt-5 pt-md-0">
            <div className="bg-light p-4 mb-3 rounded">
              <h2 className="h4">Want to speak with an Enterprise Solutions expert? Give us a call!</h2>
              <p className="h4"><a href="tel:1-866-222-6315" className="text-primary text-bold">1-866-222-6315</a></p>
            </div>
          </div>
        </div>
      </div>
    </div>

  </>;
}
