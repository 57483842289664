import React from 'react';
import PropTypes from 'prop-types';
import ContentBlock from '../ContentBlock';
import { useBookTurnLayoutEffect, useFormLayoutEffect, useContentOnloadLayoutEffect } from '../../../utils/hooks';

const StaticPage = ({ content }) => {

  useBookTurnLayoutEffect(content);
  useFormLayoutEffect(content);
  useContentOnloadLayoutEffect(content);

  return (
    <>
      {content.css && <style>{content.css}</style>}
      <ContentBlock html={content.body} />
    </>
  );
};

StaticPage.propTypes = {
  content: PropTypes.shape({
    body: PropTypes.string.isRequired,
    css: PropTypes.string
  }).isRequired
};

export default StaticPage;
