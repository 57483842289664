import React from 'react';
import { Link } from 'react-router-dom';

import logo from '../../img/SP_LG_Enterprise_RGB_ColorRev_Horiz.svg';
import logoCondensed from '../../img/SP_LG_Enterprise_RGB_ColorRev_Condensed.png';

export default ({ children }) => <div className="bg-dark bg-image container-fluid pb-3" style={{ backgroundImage: 'url("https://content.skillpath.com/web/img/page_banners/PS_WS_Page-Banner_13.jpg")', backgroundPositionY: '-10em' }}>
  <div className="overlay" style={{ opacity: '.7' }}></div>

  <div className="container pb-4 pb-lg-5 z-index-3 position-relative">
    <div className="row justify-content-between">
      <div className="col-xl-7 pt-5 pb-xl-0">
        <div className="row pb-4">
          <div className="col-12 pt-2">
            <Link to="/onsite">
              <img src={logo} style={{ width: '450px' }} className="mb-3 d-none d-md-block" alt="SkillPath Enterprise Solutions" />
              <img src={logoCondensed} style={{ width: '200px' }} className="mb-3 d-md-none" alt="SkillPath Enterprise Solutions" />
            </Link>
            {children}
            <button type="button" className="btn btn-primary mr-2 d-xl-none" data-toggle="modal" data-target="#needsModal"><i className="far fa-check-circle mr-2"></i>Connect with a learning consultant</button>
          </div>
        </div>

      </div>
      <div className="col-xl-4 position-relative d-none d-xl-block pt-5">
        <div className="bg-primary p-4 rounded mt-2" style={{ width: '100%' }} data-aos="fade-down" data-aos-duration="1000">
          <div className="d-table-row">
            <div className="d-table cell align-top">
              <p className="text-white mb-3 h4">Take your organization to its next level of excellence</p>
              <button type="button" className="btn btn-outline-light" data-toggle="modal" data-target="#needsModal"><i className="far fa-check-circle mr-2"></i>Connect with a learning consultant</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
