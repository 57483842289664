import React, { useCallback } from 'react';
import { getIn } from 'formik';
import { FormFeedback } from 'reactstrap';
import classNames from 'classnames';
import { Field } from 'formik';
import { FormGroup } from 'reactstrap';
import NumberFormat from 'react-number-format';

const getValidationState = (touched, error, status) =>
  showError(touched, error, status) ? 'is-invalid' : undefined;

const showError = (touched, error, status) =>
  (touched && error) || (status === 'submitted' && error);

export const TextField = ({ field, form: { touched, errors, status }, className, ...props }) => {

  const isTouched = getIn(touched, field.name), error = getIn(errors, field.name);

  return (
    <>
      <input type="text" {...field} {...props} className={classNames('form-control', className, getValidationState(isTouched, error, status))} />
      {showError(isTouched, error, status) &&
        <FormFeedback>{error}</FormFeedback>}
    </>
  );
}

export const NumberField = ({ field: { onChange, ...restOfField }, form: { touched, errors, status, setFieldValue }, className, setAsFormatted, ...props }) => {
  // NOTE: plucking off onChange so it is not spread into NumberFormat, it was overriding onValueChange
  const fieldName = restOfField.name;
  const isTouched = getIn(touched, fieldName), error = getIn(errors, fieldName);

  return (
    <>
      <NumberFormat
        {...restOfField}
        {...props}
        onValueChange={(values) => {
          const setAsValue = setAsFormatted ? values.formattedValue : values.value;
          if (setAsValue !== restOfField.value) {
            setFieldValue(fieldName, setAsValue);
          }
        }}
        className={classNames('form-control', className, getValidationState(isTouched, error, status))}
      />
      {showError(isTouched, error, status) &&
        <FormFeedback>{error}</FormFeedback>}
    </>
  );
}

export const SelectField = ({ field, form: { touched, errors, status }, children, className, ...props }) => {

  const isTouched = getIn(touched, field.name), error = getIn(errors, field.name);

  return (
    <>
      <select {...field} {...props} className={classNames('custom-select', className, getValidationState(isTouched, error, status))}>
        {children}
      </select>
      {showError(isTouched, error, status) &&
        <FormFeedback>{error}</FormFeedback>}
    </>
  );
}

export const CheckboxField = ({ field, form: { touched, errors, status }, label, className, labelClassName, labelStyle, wrapperClassName, wrapperStyle, ...props }) => {

  const isTouched = getIn(touched, field.name)
    , error = getIn(errors, field.name)
    , hasError = showError(isTouched, error, status);

  return (
    <div className={classNames('custom-control custom-checkbox', wrapperClassName)} style={wrapperStyle} >
      <input type="checkbox" id={field.name} {...field} {...props} className={classNames('custom-control-input', className, { 'is-invalid': hasError })} />
      <label className={classNames('custom-control-label', labelClassName)} style={labelStyle} htmlFor={field.name}>{label}</label>
      {hasError && <FormFeedback>{error}</FormFeedback>}
    </div>
  );
}

export const RadioBooleanField = ({ field: { onChange, ...restOfField }, form: { touched, errors, status, setFieldValue }, label, className, suppressError, inline, ...props }) => {

  // pluck off onChange and handle setting from "true"/"false" to true/false
  const isTouched = getIn(touched, restOfField.name)
    , error = getIn(errors, restOfField.name)
    , hasError = !suppressError && showError(isTouched, error, status); // use suppressError to keep the error message from showing for each radio button

  const handleChange = useCallback((e) => {
    if (e.target.value === "true") {
      setFieldValue(restOfField.name, true);
    }
    else if (e.target.value === "false") {
      setFieldValue(restOfField.name, false);
    }
    else {
      onChange(e);
    }
  }, [onChange, setFieldValue, restOfField.name]);

  return (
    <div className={classNames('custom-control custom-radio', { 'custom-control-inline': inline })}>
      <input type="radio" {...restOfField} onChange={handleChange} {...props} className={classNames('custom-control-input', className, { 'is-invalid': hasError })} />
      <label className="custom-control-label" htmlFor={props.id}>{label}</label>
      {hasError && <FormFeedback>{error}</FormFeedback>}
    </div>
  );
}

export const RadioField = ({ field, form: { touched, errors, status }, label, className, suppressError, ...props }) => {

  const isTouched = getIn(touched, field.name)
    , error = getIn(errors, field.name)
    , hasError = !suppressError && showError(isTouched, error, status); // use suppressError to keep the error message from showing for each radio button

  return (
    <div className="custom-control custom-radio">
      <input type="radio" {...field} {...props} className={classNames('custom-control-input', className, { 'is-invalid': hasError })} />
      <label className="custom-control-label" htmlFor={props.id}>{label}</label>
      {hasError && <FormFeedback>{error}</FormFeedback>}
    </div>
  );
}

export const StaticField = ({ field, form, className, ...props }) => {
  return (
    <div className="static-field">{field.value}</div>
  );
}

export const TextAreaField = ({ field, form: { touched, errors, status }, className, ...props }) => {

  const isTouched = getIn(touched, field.name), error = getIn(errors, field.name);

  return (
    <>
      <textarea {...field} {...props} className={classNames('form-control', className, getValidationState(isTouched, error, status))}></textarea>
      {showError(isTouched, error, status) &&
        <FormFeedback>{error}</FormFeedback>}
    </>
  );
}

export const Tooltip = ({ tooltip }) => (
  <button className="btn btn-link p-0 pb-1 text-secondary has-cursor" data-toggle="dropdown" aria-label="More information">
    <i className="fa fa-info-circle mr-1" />
    <div
      className="dropdown-menu p-4 bg-light has-tooltip-dropdown"
    >
      {tooltip}
    </div>
  </button>
);

export const FieldGroup = ({ name, label, tooltip, formGroupClassName, labelClassName, children, ...props }) => {
  const uniqueId = Math.floor(Math.random() * 1000000);
  return (
  <FormGroup className={formGroupClassName}>
    {tooltip && <Tooltip tooltip={tooltip} />}
    {label && <label className={labelClassName} htmlFor={name + '_' + uniqueId}>{label}</label>}
    <Field name={name} id={name + '_' + uniqueId} {...props}>
      {children}
    </Field>
  </FormGroup>
)};

