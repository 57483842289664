import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import { hasAttachment, createContentAttachmentUrl } from './ContentImage';
import { DESCRIPTION, BRANDS } from '../../constants';
import { useBrand } from '../../store/site';

const typeTitleModalityMap = new Map([
  ['page:seminar-topic', 'Seminar'],
  ['page:webinar-topic', 'Webinar'],
  ['page:virtual-topic', 'Virtual Seminar'],
  ['page:product-item', 'Product'],
  ['page:onsite-course', 'Onsite Course'],
  ['page:ondemand-course', 'On-Demand Course'],
]);

const getTitle = (title, type ) => {

  const typeTitleModality = typeTitleModalityMap.get(type);
  return typeTitleModality
    ? `${title} | ${typeTitleModality}`
    : title;
}

const getSEOType = (content, imageUrl, url, organization) => {

  const mainEntityOfPage = {
    "@type": "WebPage",
    "@id": url
  }
  const keywords = content.tags || [];

  switch (content._type) {
    case 'page:blog-post':
    case 'page:news-item':
      const type = content._type === 'page:blog-post' ? 'BlogPosting' : 'NewsArticle';
      const author = content._type === 'page:blog-post' ? { "@type": "Person", name: content.author } : organization;

      return [type, {
        headline: content.title,
        image: imageUrl,
        keywords,
        author,
        datePublished: content.publishOn,
        dateModified: content.publishOn,
        mainEntityOfPage
      }];
    default:
      return ['WebPage', { "name": content.title, keywords, mainEntityOfPage }];
  }
}

const getSEOData = (content, description, url, organization, imageUrl) => {
  const [seoType, data] = getSEOType(content, imageUrl, url, organization);
  return {
    "@context": "https://schema.org",
    "@type": seoType,
    description,
    "publisher": organization,
    ...data
  }
}

const getCanonicalUrl = (type, slug, isNST) => {
  // TODO: remove brand logic when nst goes away

  const domain = window.location.protocol + '//' + (isNST ? "skillpath.com" : window.location.host);
  let path;
  switch (type) {
    case "page:webinar-topic":
      path = `/webinar/${slug}`
      break;
    case "page:seminar-topic":
      path = `/seminar/${slug}`
      break;
    case "page:ondemand-course":
      path = `/ondemand/${slug}`
      break;
    default:
      path = window.location.pathname;
  }

  return `${domain}${path}`
}

const useSocial = () => {
  const [facebook, setFacebook] = useState('');
  const [twitterHandle, setTwitterHandle] = useState('');
  const [facebookAppId, setFacebookAppId] = useState('');

  const { brand: { social: { facebook: fb, twitterHandle: tw }, fbAppId } } = useSelector(state => state.site)

  useEffect(() => {
    setFacebook(fb);
    setTwitterHandle(tw);
    setFacebookAppId(fbAppId);
  }, [fb, tw, fbAppId]);

  return [facebook, twitterHandle, fbAppId, facebookAppId]
}

const useSEOData = (content) => {
  const [seoData, setSEOData] = useState({});
  const [isBlogOrNews, setIsBlogOrNews] = useState(false);
  const [contentData, setContentData] = useState({
    url: '',
    description: DESCRIPTION,
    title: '',
    imageUrl: '',
    publishedOn: null
  });

  const { legalName, defaultImage, social, code } = useBrand();

  useEffect(() => {

    const attachment = hasAttachment(content, 'default');
    const imageUrl = attachment ? createContentAttachmentUrl(content._doc, 'default') : defaultImage;

    const url = getCanonicalUrl(content._type, content.slug, code === BRANDS.NST.code);

    setIsBlogOrNews(content._type === 'page:blog-post' || content._type === 'page:news-item')

    const organization = {
      "@type": "Organization",
      "name": legalName,
      sameAs: Object.keys(social).filter(x => x !== "twitterHandle").map(key => social[key]),
      "logo": {
        "@type": "ImageObject",
        "url": defaultImage,
      }
    };

    const description = content.description || DESCRIPTION;
    const seoData = getSEOData(content, description, url, organization, imageUrl);

    setContentData({
      imageUrl,
      title: getTitle(content.title, content._type),
      description,
      url,
      publishOn: content.publishOn
    });
    setSEOData(seoData);
  }, [content, legalName, social, defaultImage, code]);

  return [contentData.imageUrl, seoData, contentData.description, contentData.title, contentData.url,
    isBlogOrNews, content.publishOn]

}

const ContentHeader = ({ content }) => {

  const [imageUrl, seoData, description, title, url, isBlogOrNews, publishOn] = useSEOData(content);
  const [facebook, twitterHandle, fbAppId] = useSocial();

  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta property="fb:app_id" content={fbAppId} />
      <meta property="og:locale" content="en_US" />
      <meta property="og:title" content={title} />
      <meta property="og:type" content={isBlogOrNews ? 'article' : 'website'} />
      <meta property="og:url" content={url} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={imageUrl} />
      {isBlogOrNews && <meta property="article:publisher" content={facebook} />}
      {isBlogOrNews && <meta property="article:published_time" content={publishOn} />}
      {isBlogOrNews && <meta property="article:content_tier" content="free" />}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={imageUrl} />
      <meta name="twitter:site" content={twitterHandle} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:creator" content={twitterHandle} />
      <link rel="canonical" href={url} />
      <script type="application/ld+json">
        {JSON.stringify(seoData)}
      </script>
    </Helmet>);
}

ContentHeader.propTypes = {
  content: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string
  })
}

export default ContentHeader;
