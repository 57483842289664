import React from 'react';
import NumberFormat from 'react-number-format';
import { COUNTRIES, safeAdd } from '../../constants';
import {useCountry} from '../../store/site';
export const FormatPhone = ({ value }) =>
  <NumberFormat format="(###) ###-####"
    displayType="text"
    value={value} />;

export const FormatCurrency = ({ value, decimals = true, prefix = true, ...props }) =>
{
  const country = useCountry();
    return(
      <NumberFormat thousandSeparator={true}
      prefix={prefix ? "$" : ""}
      suffix= {country?.currencySuffix}
      decimalScale={decimals ? 2 : 0}
      fixedDecimalScale={true}
      displayType="text"
      value={value} />
    );
}
 

export const FormatCurrencyWithDiscount = ({ amount, discountAmount }) => (
  <>
    {(discountAmount && discountAmount > 0) ?
      <>
        <strike>
          <FormatCurrency value={safeAdd(amount, discountAmount)} />
        </strike>
        &nbsp;
        <span className="text-primary">
          <FormatCurrency value={amount} decimals={false} />
        </span>
      </> :
      <FormatCurrency value={amount} />
    }

  </>
);

export const FormatPercent = ({ value }) =>
  <NumberFormat suffix="%"
    displayType="text"
    value={value < 1.0 ? Math.floor(100 * value) : value} />;

export const formatVipNumber = (vip) => {
  if (!vip) return vip;

  vip = vip.replace(/[^A-Za-z0-9]/g, '');

  if (/[A-Za-z]/.test(vip.substr(0, 5))) {
    return vip;
  }


  if (vip.length >= 17) {
    // 5-7-4-1
    return `${vip.substr(0, 5)}-${vip.substr(5, 7)}-${vip.substr(12, 4)}-${vip.substr(16, 1)}`;
  }

  if (vip.length === 16) {
    // 5-6-4-1
    return `${vip.substr(0, 5)}-${vip.substr(5, 6)}-${vip.substr(11, 4)}-${vip.substr(15, 1)}`;
  }

  // 6-7-2, use what is entered, no trailing
  return `${vip.substr(0, 6)}-${vip.substr(6, 7)}-${vip.substr(13, 2)}`.replace(/-+$/, '');
}

function limit(val, max) {
  if (val.length === 1 && val[0] > max[0]) {
    val = '0' + val;
  }

  if (val.length === 2) {
    if (Number(val) === 0) {
      val = '01';

      //this can happen when the user pastes a number
    } else if (val > max) {
      val = max;
    }
  }

  return val;
}

export const formatCardExpiration = (val) => {
  let month = limit(val.substring(0, 2), '12');
  let year = val.substring(2, 4);

  return month + (year.length ? '/' + year : '');
}
