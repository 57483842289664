import React from 'react';
import PropTypes from 'prop-types';
import GenericSearchBar from './GenericSearchBar';

const Error = ({ error = {} }) => (
  <div className="container">
    <div className="row">
      <div className="col">
        <h1 className="text-center pb-2">{error.message || 'An error occured'}</h1>
        {error.stackTrace && <pre className="stackTrace">{error.stackTrace}</pre>}
        {!error.stackTrace && <React.Fragment>
          <p className="text-center pb-3">Please try again or try using the search form below</p>
          <div className="search-form-container">
            <GenericSearchBar placeholder="Search by keyword" largeIcon={true} />
          </div>
        </React.Fragment>}
      </div>
    </div>
  </div>
);

Error.propTypes = {
  error: PropTypes.shape({
    message: PropTypes.string,
    stackTrace: PropTypes.string
  }).isRequired
};

export default Error;
