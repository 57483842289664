import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import * as Cart from '../../store/cart';

import { FormatCurrency } from '../Shared/Formatters';


const ProductPreviewTable = ({ product, addProductToCart }) => {

  const dispatch = useDispatch();
  const defaultAddProductToCart = useCallback(({ productId, relatedProductId }) =>
    dispatch(Cart.actionCreators.addProductCheckRelated(productId, relatedProductId))
    , [dispatch]);

  return (
    <div className="productTable">
      <div className="productTableBody">
        <div className="productTableRow">
          <div className="d-none">
            productId: {product.productId}, shortCode: {product.shortCode}
          </div>
          <div className="productTableCell" style={{ width: '30%' }}>{product.productTypeName}</div>
          <div className="productTableCell"><FormatCurrency value={product.price} /></div>
          <div className="productTableCell pt-2">
            <button className="btn btn-outline-primary bold" onClick={() => {
              if (addProductToCart) {
                addProductToCart(product)
              }
              else {
                defaultAddProductToCart(product)
              }
            }}>
              Add to cart
          </button>
          </div>
        </div>
      </div>
    </div>
  )
};

ProductPreviewTable.propTypes = {
  product: PropTypes.shape({
    productId: PropTypes.number.isRequired,
    productTypeName: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired
  }).isRequired,
};

export default ProductPreviewTable;

