import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { getDisplayName } from '../../utils/component';
import NotFound from '../Shared/NotFound';
import Error from '../Shared/Error';
import ContentPageWrapper from './ContentPageWrapper';

import {
  pageContentForRoute,
  pageContentForType
} from '../../ajax/content';

import LoadingSpinner from '../Shared/LoadingSpinner';
import { useLocation } from 'react-router';

const withPageContent = (WrappedComponent) => {

  const WithPageContent = ({ contentRoute, contentType, children, ...rest }) => {

    const [content, setContent] = useState(null);

    const location = useLocation();
    useEffect(() => {
      const getContentFor = async action => {
        try {
          setContent(await action());
        } catch (error) {
          setContent({ ...error });
        }
      }

      if (contentType) {
        getContentFor(() => pageContentForType(contentType));
      } else {
        getContentFor(() => pageContentForRoute(contentRoute || location.pathname))
      }
    }, [contentRoute, contentType, location.pathname]);

    if (!content) {
      return <LoadingSpinner />;
    }

    if (!content._type) {
      return content.response && content.response.status === 404
        ? <NotFound />
        : <Error error={content.response && content.response.data} />
    }

    return (
      <ContentPageWrapper content={content}>
        <WrappedComponent {...rest} content={content}>
          {children}
        </WrappedComponent>
      </ContentPageWrapper>
    );
  }

  WithPageContent.propTypes = {
    contentRoute: PropTypes.string,
    contentType: PropTypes.string
  }
  WithPageContent.displayName = `WithPageContent(${getDisplayName(WrappedComponent)})`;
  return WithPageContent;
}

export default withPageContent;
