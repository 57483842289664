import React, { useEffect, useState } from 'react';
import classNames from 'classnames';

const styles = {
  position: 'fixed',
  bottom: '20px',
  right: '30px',
  zIndex: '1099',
  border: 'none',
  outline: 'none',
  backgroundColor: '#000',
  opacity: '.80',
  color: 'white',
  cursor: 'pointer',
  padding: '10px',
  borderRadius: '10px',
  fontSize: '18px',
};

const BackToTopButton = () => {

  const [visible, setVisible] = useState(false);

  useEffect(() => {

    const handleScroll = function () {
      // https://stackoverflow.com/a/20478983
      setVisible(window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0 > 20);
    }

    window.addEventListener('scroll', handleScroll);

    return function () {
      window.removeEventListener('scroll', handleScroll);
    };

  }, []);

  return <button type="button" className={classNames('top-btn', { 'd-none': !visible })} style={styles} onClick={() => window.scrollTo(0, 0)}>&#8679; Top</button>
}

export default BackToTopButton
