import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { NavLink, Link } from 'react-router-dom';
import { NavItem } from 'reactstrap';
import { useHasSeminars } from '../../store/site';

const MENUS = {
  MAIN: 'Main Menu',
  TOPICS: 'Topics',
  ONSITE: 'Group Training'
};

const SideNavigation = React.memo(({ onToggle, open }) => {
  const [menu, setMenu] = useState(MENUS.MAIN);

  const toggleClose = useCallback(() => {
    onToggle();
    setMenu(MENUS.MAIN); //reset to main
  }, [onToggle])

  const hasSeminars = useHasSeminars();

  return <>
    <div className={classNames('SideNavigation navbar-collapse d-xl-none', { 'show': open })} id="SideNavigation">
      <div className="row m-0 py-2 px-1 bg-darker">
        <div className="col">
          <h5 className="text-light mt-2 no-wrap">
            {menu === MENUS.TOPICS && <button onClick={() => setMenu(MENUS.MAIN)} className="btn p-0 m-0 text-light" aria-label="Back to main menu"><i className="fas fa-chevron-left pr-3"></i></button>}
            {menu === MENUS.ONSITE && <button onClick={() => setMenu(MENUS.MAIN)} className="btn p-0 m-0 text-light" aria-label="Back to main menu"><i className="fas fa-chevron-left pr-3"></i></button>}
            {menu}
          </h5>
        </div>
        <div className="col text-right">
          <button type="button" className="navbar-toggler pt-0" onClick={toggleClose} aria-controls="SideNavigation" aria-label="Close side navigation">
            <span className="fas fa-times"></span>
          </button>
        </div>
      </div>
      <ul onClick={toggleClose} className={classNames({'d-none' : !open})}>

        {menu === MENUS.MAIN && <>
          <NavItem>
            <NavLink to="/category" className="with-sub" onClick={(e) => { e.stopPropagation(); setMenu(MENUS.TOPICS); }}>Topics</NavLink>
          </NavItem>
          {hasSeminars &&
            <NavItem>
              <NavLink to="/seminar">In-Person Events</NavLink>
            </NavItem>
          }
          <NavItem>
            <NavLink to="/virtual">Virtual Seminars</NavLink>
          </NavItem>
          <NavItem>
            <NavLink to="/webinar">Webinars</NavLink>
          </NavItem>
          <NavItem>
            <NavLink to="/product">Products</NavLink>
          </NavItem>
          <NavItem>
            <NavLink to="/onsite" className="with-sub" onClick={(e) => { e.stopPropagation(); setMenu(MENUS.ONSITE); }}>Group Training</NavLink>
          </NavItem>
          <NavItem>
            <NavLink to="/unlimited">SkillPath Unlimited</NavLink>
          </NavItem>
          <NavItem>
            <NavLink to="/ondemand">On-Demand Library</NavLink>
          </NavItem>
          <NavItem>
            <NavLink to="/resources">Free Resources</NavLink>
          </NavItem>
          <NavItem>
            <NavLink to="/blog">Blog</NavLink>
          </NavItem>
          <NavItem>
            <NavLink to="/about">About</NavLink>
          </NavItem>
          <NavItem>
            <NavLink to="/contact">Contact</NavLink>
          </NavItem>
        </>}

        {menu === MENUS.TOPICS && <>
          <NavItem>
            <Link to="/category/administrative-support-training">Administrative Support Training</Link>
          </NavItem>
          <NavItem>
            <Link to="/category/business-writing-advertising">Business Writing/Advertising</Link>
          </NavItem>
          <NavItem>
            <Link to="/category/customer-service">Customer Service</Link>
          </NavItem>
          <NavItem>
            <Link to="/category/finance-and-accounting">Finance and Accounting</Link>
          </NavItem>
          <NavItem>
            <Link to="/category/human-resources-osha">Human Resources/OSHA</Link>
          </NavItem>
          <NavItem>
            <Link to="/category/management-and-supervisory">Management and Supervisory</Link>
          </NavItem>
          <NavItem>
            <Link to="/category/microsoft-excel-and-office-applications">Microsoft Excel and Office Applications</Link>
          </NavItem>
          <NavItem>
            <Link to="/category/personal-development-communication">Personal Development/Communication</Link>
          </NavItem>
          <NavItem>
            <Link to="/category/project-and-time-management">Project and Time Management</Link>
          </NavItem>
          <NavItem>
            <Link to="/category/social-media">Social Media</Link>
          </NavItem>
          <NavItem>
            <Link to="/category">View All</Link>
          </NavItem>
        </>}

        {menu === MENUS.ONSITE && <>
          <NavItem>
            <Link to="/onsite">About Group Training</Link>
          </NavItem>
          <NavItem>
            <Link to="/onsite/whoweare">Who We Are</Link>
          </NavItem>
          <NavItem>
            <Link to="/onsite/lxp">Learning Experience Platform</Link>
          </NavItem>
          <NavItem>
            <Link to="/onsite/course">Courses</Link>
          </NavItem>
          <NavItem>
            <Link to="/onsite/methodology">Methodology</Link>
          </NavItem>
          <NavItem>
            <Link to="/onsite/modality">Modalities</Link>
          </NavItem>
          <NavItem>
            <Link to="/onsite/industry">Industries</Link>
          </NavItem>
          <NavItem>
            <Link to="/onsite/reviews">Reviews</Link>
          </NavItem>
          <NavItem>
            <Link to="/onsite/contact">Contact Us</Link>
          </NavItem>
        </>}

      </ul>
    </div>
  </>

});

SideNavigation.propTypes = {
  onToggle: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired
};

export default SideNavigation;
