import React from 'react';

import { COUNTRIES, PAYMENT_METHODS } from '../../constants';
import { useCountry } from '../../store/site';

export const SalutationOptions = () => (
  <>
    <option value="Mr">Mr.</option>
    <option value="Ms">Ms.</option>
  </>
);

const InnerStateOptions = ({ country: { abbr } }) => (
  abbr === COUNTRIES.US.abbr ?
    <>
      <option value="AL">Alabama</option>
      <option value="AK">Alaska</option>
      <option value="AZ">Arizona</option>
      <option value="AR">Arkansas</option>
      <option value="CA">California</option>
      <option value="CO">Colorado</option>
      <option value="CT">Connecticut</option>
      <option value="DE">Delaware</option>
      <option value="DC">District Of Columbia</option>
      <option value="FL">Florida</option>
      <option value="GA">Georgia</option>
      <option value="HI">Hawaii</option>
      <option value="ID">Idaho</option>
      <option value="IL">Illinois</option>
      <option value="IN">Indiana</option>
      <option value="IA">Iowa</option>
      <option value="KS">Kansas</option>
      <option value="KY">Kentucky</option>
      <option value="LA">Louisiana</option>
      <option value="ME">Maine</option>
      <option value="MD">Maryland</option>
      <option value="MA">Massachusetts</option>
      <option value="MI">Michigan</option>
      <option value="MN">Minnesota</option>
      <option value="MS">Mississippi</option>
      <option value="MO">Missouri</option>
      <option value="MT">Montana</option>
      <option value="NE">Nebraska</option>
      <option value="NV">Nevada</option>
      <option value="NH">New Hampshire</option>
      <option value="NJ">New Jersey</option>
      <option value="NM">New Mexico</option>
      <option value="NY">New York</option>
      <option value="NC">North Carolina</option>
      <option value="ND">North Dakota</option>
      <option value="OH">Ohio</option>
      <option value="OK">Oklahoma</option>
      <option value="OR">Oregon</option>
      <option value="PA">Pennsylvania</option>
      <option value="PR">Puerto Rico</option>
      <option value="RI">Rhode Island</option>
      <option value="SC">South Carolina</option>
      <option value="SD">South Dakota</option>
      <option value="TN">Tennessee</option>
      <option value="TX">Texas</option>
      <option value="UT">Utah</option>
      <option value="VT">Vermont</option>
      <option value="VI">Virgin Islands</option>
      <option value="VA">Virginia</option>
      <option value="WA">Washington</option>
      <option value="WV">West Virginia</option>
      <option value="WI">Wisconsin</option>
      <option value="WY">Wyoming</option>
    </> :
    <>
      <option value="AB">Alberta</option>
      <option value="BC">British Columbia</option>
      <option value="MB">Manitoba</option>
      <option value="NB">New Brunswick</option>
      <option value="NL">Newfoundland and Labrador</option>
      <option value="NS">Nova Scotia</option>
      <option value="NT">Northwest Territories</option>
      <option value="NU">Nunavut</option>
      <option value="ON">Ontario</option>
      <option value="PE">Prince Edward Island</option>
      <option value="QC">Quebec</option>
      <option value="SK">Saskatchewan</option>
      <option value="YT">Yukon Territories</option>
    </>
);

export const CountryOptions = () => (
  <>
    <option value={COUNTRIES?.US.abbr}>{COUNTRIES?.US.name}</option>
    <option value={COUNTRIES?.CA.abbr}>{COUNTRIES?.CA.name}</option>
  </>
);
  

export const StateOptions = ({ country }) => {

  const siteCountry = useCountry();

  return <InnerStateOptions country={country || siteCountry} />
}

export const HeardAboutOptions = () => (
  <>
    <option value="BROCH">Brochure</option>
    <option value="CAT">Catalog</option>
    <option value="EMAIL">Email</option>
    <option value="FRIEND">Friend/Colleague</option>
    <option value="PAST">Past Customer</option>
    <option value="WEB">Web</option>
    <option value="OTHER">Other</option>
  </>
);

export const CreditCardTypeOptions = () => (
  <>
    <option key="Am" value="Am">American Express</option>
    <option key="Di" value="Di">Discover</option>
    <option key="Ma" value="Ma">MasterCard</option>
    <option key="Vi" value="Vi">Visa</option>
  </>
);

export const PaymentMethodOptions = ({isCustomerService}) => {
  const options = [];

  if(isCustomerService){
    for (const paymentMethod in PAYMENT_METHODS) {
      const pm = PAYMENT_METHODS[paymentMethod];
      if (pm !== PAYMENT_METHODS.COMPLIMENTARY) {
        options.push(<option key={pm.abbr} value={pm.abbr}>{pm.name}</option>)
      }
    }
  } else {
    options.push(<option key={PAYMENT_METHODS.CREDIT_CARD.abbr} value={PAYMENT_METHODS.CREDIT_CARD.abbr}>{PAYMENT_METHODS.CREDIT_CARD.name}</option>)
  }
  
  return <>{options}</>;
}

export const MonthOptions = () => (
  <>
    <option key="1" value="1">Jan</option>
    <option key="2" value="2">Feb</option>
    <option key="3" value="3">Mar</option>
    <option key="4" value="4">Apr</option>
    <option key="5" value="5">May</option>
    <option key="6" value="6">Jun</option>
    <option key="7" value="7">Jul</option>
    <option key="8" value="8">Aug</option>
    <option key="9" value="9">Sep</option>
    <option key="10" value="10">Oct</option>
    <option key="11" value="11">Nov</option>
    <option key="12" value="12">Dec</option>
  </>
)

export const ExpirationYear = () => {
  const createOptions = () => {
    let year = new Date().getFullYear();
    let options = [];
    for (var i = year; i <= year + 10; i++) {
      options.push(<option key={i} value={i.toString().substr(-2)}>{i}</option>)
    }

    return options;
  }
  return (
    <>
      {createOptions()}
    </>
  )
}

export const ShippingOptions = () => {

  const { shippingMethods } = useCountry();

  const options = [];
  for (const shippingMethod in shippingMethods) {
    options.push(<option key={shippingMethod} value={shippingMethod}>{shippingMethods[shippingMethod]}</option>)
  }
  return <>{options}</>;
}

export const ProductTypeOptions = () => (
  <>
    <option value="1">Audio</option>
    <option value="2">Book</option>
    <option value="3">Book set</option>
    <option value="4">CD</option>
    <option value="5">DVD</option>
    <option value="6">Package</option>
    <option value="7">Software</option>
    <option value="8">USB</option>
    <option value="9">Video</option>
    <option value="10">Webinar</option>
    <option value="11">Digital</option>
  </>
);

export const WithinOptions = () => <>{[10, 25, 50, 100, 150, 200, 250, 3000].map(x => <option key={x} value={x}>{ x < 3000 ? x : 'Any' } miles</option>)}</>

export const ResourceTypeOptions = () => (
  <>
    <option value="infographic">Infographic</option>
    <option value="one sheet">One Sheet</option>
    <option value="white paper">White Paper</option>
    <option value="video">Video</option>
    <option value="article">Article</option>
  </>
);
