import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ATTENDEE_ERRORS } from '../constants';

const SHOW = 'MODAL/SHOW';
const HIDE = 'MODAL/HIDE';

const initialState = {
  type: null,
  props: {},
  isOpen: false
};

export const modalTypes = {
  GO_TO_CART: 'GO_TO_CART',
  PRODUCT_UPSELL: 'PRODUCT_UPSELL',
  CONFIRM_DIALOG: 'CONFIRM_DIALOG',
  ALERT_DIALOG: 'ALERT_DIALOG',
  VALIDATION_FAILED: 'VALIDATION_FAILED'
}

export const actionCreators = {
  show: (type, props = {}) => ({ type: SHOW, payload: { type, props } }),
  hide: () => ({ type: HIDE }),
  showResponseErrors: ({ response } /* error */) => (dispatch) => {
    if (!response) {
      return;
    }

    if (response.status === 400) {

      dispatch(actionCreators.showValidationFailed(response.data));
    } else {

      const modalProps = {
        body: response.data.message || 'An unexpected error has occured'
      };
      dispatch(actionCreators.show(modalTypes.ALERT_DIALOG, modalProps));
    }
  },
  showValidationFailed: (data) => (dispatch, getState) => {

    if (data.errors[ATTENDEE_ERRORS.DUPLICATE_REGISTRATION]) {

      const { site: { brand } } = getState();
      const modalProps = {
        title: 'DUPLICATE REGISTRATION',
        body: <div>{data.errors[ATTENDEE_ERRORS.DUPLICATE_REGISTRATION]}: You are already registered for this event.<br />If you need additional assistance, please contact Customer Service at <b>{brand.phone}</b>.</div>,
        okBSStyle: 'warning'
      };
      dispatch(actionCreators.show(modalTypes.ALERT_DIALOG, modalProps));
    }
    else if (data.errors[ATTENDEE_ERRORS.EXISTING_MEMBERSHIP]) {

      const { site: { brand } } = getState();
      const modalProps = {
        title: 'EXISTING MEMBERSHIP',
        body: <div>{data.errors[ATTENDEE_ERRORS.EXISTING_MEMBERSHIP]}: You have an existing subscription.<br />If you need additional assistance, please contact Customer Service at <b>{brand.phone}</b>.</div>,
        okBSStyle: 'warning'
      };
      dispatch(actionCreators.show(modalTypes.ALERT_DIALOG, modalProps));
    }
    else if (data.errors[ATTENDEE_ERRORS.PENDING_STAR12_RENEWAL]) {

      const { site: { brand } } = getState();
      const modalProps = {
        title: 'PENDING RENEWAL',
        body: <div>{data.errors[ATTENDEE_ERRORS.PENDING_STAR12_RENEWAL]}: You have an existing subscription renewal pending.<br />If you need additional assistance, please contact Customer Service at <b>{brand.phone}</b>.</div>,
        okBSStyle: 'warning'
      };
      dispatch(actionCreators.show(modalTypes.ALERT_DIALOG, modalProps));
    }
    else if (data.errors[ATTENDEE_ERRORS.PENDING_STAR12_PURCHASE]) {

      const { site: { brand } } = getState();
      const modalProps = {
        title: 'PENDING MEMBERSHIP',
        body: <div>{data.errors[ATTENDEE_ERRORS.PENDING_STAR12_PURCHASE]}: There is already a pending subscription associated with this email address.<br />Please contact Customer Service at <b>{brand.phone}</b> for assistance.</div>,
        okBSStyle: 'warning'
      };
      dispatch(actionCreators.show(modalTypes.ALERT_DIALOG, modalProps));
    }
    else {

      const modalProps = {
        data
      };
      dispatch(actionCreators.show(modalTypes.VALIDATION_FAILED, modalProps));
    }
  }
};

export const reducer = (state, action) => {
  state = state || initialState;

  if (action.type === SHOW) {
    return { ...action.payload, isOpen: true };
  }

  if (action.type === HIDE) {
    return { ...state, isOpen: false };
  }

  return state;
};

export const useShowModal = () => {

  const dispatch = useDispatch();

  const showModal = useCallback((type, props = {}) => {

    dispatch({ type: SHOW, payload: { type, props } });
  }, [dispatch]);

  return showModal;
};

export const useModal = () => {

  const dispatch = useDispatch();
  const { type, props, isOpen } = useSelector(state => state.modal);

  const toggle = useCallback(() => {

    dispatch({ type: HIDE });
  }, [dispatch]);

  return [type, props, isOpen, toggle];
};
