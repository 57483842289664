import React, { useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Link } from 'react-router-dom';
import { get, save, complete, companionSeminarOffered } from '../ajax/cart';
import { loadRecommended } from '../ajax/topic';
import { forType, forShortCode } from '../ajax/catalog';
import { CATALOG_TYPES, PAYMENT_METHODS, PASSES, PASSTA, PASSTD } from '../constants';
import { useCustomerService } from './customerService.reducer';

import { push } from 'connected-react-router';
import * as Modal from './modal';
import { ATTENDEE_ERRORS, UPSELL_REASONS } from '../constants';
import * as gtm from './gtm';

import isString from 'lodash/isString';
import isInteger from 'lodash/isInteger';

const LOAD = 'CART/LOAD';
const SET = 'CART/SET';
const SET_UI_KEY = 'CART/SET_UI_KEY';

const initialState = {
  clientKey: null,
  shippingSameAsBilling: true,
  items: [],
  attendees: [],
  ui: {},
};

const createCleanCart = (getState) => {
  const {
    site: {
      launchQueryParams,
      brand: { code },
      country: { abbr },
    },
  } = getState();
  return {
    ...initialState,
    brandCode: code,
    countryCode: abbr,
    vipNumber: launchQueryParams.vipNumber,
    sourceOverride: launchQueryParams.sourceOverride,
  };
};

const getClonedCart = (getState) => {
  const { cart } = getState();
  return cloneCart(cart);
};

const cloneCart = (cart) => {
  return {
    ...cart,
    attendees: cart.attendees.map((ci) => ({ ...ci })),
    items: cart.items.map((ci) => ({
      ...ci,
      attendees: ci.attendees.map((cia) => ({ ...cia })),
    })),
  };
};

// NOTE: this cart should be the cloned cart with any modifications
const saveCart = async (dispatch, getState, cart) => {
  try {
    // add customer service user email if exist.
    const { customerService } = getState();
    if(customerService?.email){
      cart.customerRep = customerService?.email;
    }

    // some how we are getting an array in vipNumber
    if (cart.vipNumber && Array.isArray(cart.vipNumber)) {
      cart.vipNumber = cart.vipNumber[0];
    }

    // keep a list of newly added items for gtm
    const newCatalogIds = cart.items.filter((ci) => ci.cartItemId <= 0).map((ci) => ci.catalogId);
    let newCart = await save(cart);

    // when all items are removed from the cart, issue a new one.
    if (newCart.items.length === 0) {
      newCart = createCleanCart(getState);
    }

    dispatch({ type: SET, cart: newCart });
    for (const catalogId of newCatalogIds) {
      const item = newCart.items.find((ci) => ci.catalogId === catalogId);
      item && dispatch(gtm.actionCreators.addItem(item));
    }

    // return state so any SET dispatch changes get applied
    return getState().cart;
  } catch (e) {
    dispatch(Modal.actionCreators.showResponseErrors(e));
    throw e;
  }
};

const changeAttendeRenewalSku = (renewalSku) => {
  const skuMappings = {
    PASSRA: PASSTA,
    PASSRD: PASSTD,
  };
  return skuMappings[renewalSku] || renewalSku;
};

//  items
//    can be an single item or an array.each can be an integer or an object with a catalogId
//    if the item is already in the cart, the item is merged onto it (allowing upsell, etc. to be changed)
//  attendee
//    can be an email address or an attendee object with a cartAttendeeId (from the UI)
//    if an email address, looks up the
//    the attendee is added to each item
//      for products, the server side save cleans them off. we do not always have the catalogTypeId here to make the decision
//  itemAttendee
//    used to pass additional information and is merged into the cart item attendee
//  cart
//    if you have a cloned cart, pass it and it will be used
const addItems = async (dispatch, getState, { items, attendee, itemAttendee, tiRenewal, renewalPrice, tiConversionPage, cart = getClonedCart(getState), firstName, lastName, isRenewal}) => {
  if (attendee) {
    if (isString(attendee)) {
      const email = attendee.toLowerCase();
      attendee = cart.attendees.find((ca) => ca.email.toLowerCase() === email) || { cartAttendeeId: 0, email, firstName, lastName};
 
      if (tiConversionPage) {
        attendee?.memberInfo?.renewalPrice && (attendee.memberInfo.renewalPrice = renewalPrice);
      }
    }
    attendee.cartAttendeeId === 0 && cart.attendees.push(attendee)
  }

  if (tiRenewal && tiConversionPage) {
    cart.tiRenewal = tiRenewal;
  }
  // ensure items is an array and each item is an object
  items = (Array.isArray(items) ? items : [items]).map((item) => (isInteger(item) ? { catalogId: item , isRenewal } : item));

  let cartItemId = 0;
  for (const item of items) {
    let cartItem = cart.items.find((ci) => ci.catalogId === item.catalogId);
    if (!cartItem) {
      cartItem = { cartItemId, quantity: 1, ...item };
      --cartItemId;
      cart.items.unshift(cartItem);
    } else {
      Object.assign(cartItem, item);
    }

    if (attendee) {
      cartItem.attendees = cartItem.attendees || [];
      let cartItemAttendee = cartItem.attendees.find((cia) => cia.cartAttendeeId === attendee.cartAttendeeId);
      if (!cartItemAttendee) {
        cartItemAttendee = {
          cartItemId: cartItem.cartItemId,
          cartAttendeeId: attendee.cartAttendeeId,
          ...itemAttendee,
        };
        cartItem.attendees.unshift(cartItemAttendee);
      } else {
        Object.assign(cartItemAttendee, itemAttendee);
      }
    }
  }

  const newCart = await saveCart(dispatch, getState, cart);

  if (newCart.saveError) {
    const data = {
      errors: {
        [newCart.saveError.key]: newCart.saveError.value,
      },
    };
    dispatch(Modal.actionCreators.showValidationFailed(data));
  } else if (attendee) {
    newCart.items.forEach((ci) => {
      ci.attendees
        .filter((cia) => cia.memberError === ATTENDEE_ERRORS.EXPIRED_BEFORE_EVENT)
        .forEach((cia) => {
          const key = `${ATTENDEE_ERRORS.EXPIRED_BEFORE_EVENT}_${cia.cartItemId}_${cia.cartAttendeeId}`;
          if (newCart.ui[key] !== 'shown') {
            const linkClick = (e) => dispatch(Modal.actionCreators.hide());
            const errorAttendee = newCart.attendees.find((a) => a.cartAttendeeId === cia.cartAttendeeId);
            const modalProps = {
              title: 'NOTICE',
              body: (
                <div>
                  {errorAttendee.email}: Your subscription expires before this event takes place, so we'll need to charge full
                  price unless you renew today.{' '}
                  <Link to={`/star12/renewal?cust=${btoa(errorAttendee.email)}`} onClick={linkClick}>
                    Click here to renew
                  </Link>{' '}
                  or by calling <b>1-800-258-7246</b>.
                </div>
              ),
              okBSStyle: 'warning',
              onOk: () => {
                dispatch({ type: SET_UI_KEY, key, value: 'shown' });
              },
            };
            dispatch(Modal.actionCreators.show(Modal.modalTypes.ALERT_DIALOG, modalProps));
          }
        });
    });
  }

  return newCart;
};

export const actionCreators = {
  loadCleanCart: () => (dispatch, getState) => {
    const cart = createCleanCart(getState);
    dispatch({ type: LOAD, cart });
  },
  load: (clientKey) => async (dispatch, getState) => {
    try {
      const {
        site: { launchQueryParams },
      } = getState();

      const cart = await get(clientKey, launchQueryParams.vipNumber, launchQueryParams.sourceOverride);
      if (cart && cart.clientKey) {
        dispatch({ type: LOAD, cart });
      } else {
        dispatch(actionCreators.loadCleanCart());
      }
    } catch (e) {
      dispatch(actionCreators.loadCleanCart());
    }
  },
  addItem: (catalogId, presentGoToCart, attendee, webinarDate) => async (dispatch, getState) => {
    await addItems(dispatch, getState, {
      items: [
        {
          catalogId: catalogId,
          webinarDate: webinarDate
        },
      ],
      attendee,
    });

    if (presentGoToCart) {
      dispatch(Modal.actionCreators.show(Modal.modalTypes.GO_TO_CART));
    }
  },
  addProductCheckRelated: (productId, relatedProductId) => async (dispatch, getState) => {
    // only use when adding a product from a shopping page

    const cart = getClonedCart(getState);
    const firstTime = !cart.items.find((ci) => ci.catalogId === productId);
    await addItems(dispatch, getState, {
      items: productId,
      cart,
    });

    // only present first time added to the cart and the related item is not already in the cart
    if (firstTime && relatedProductId && !cart.items.find((ci) => ci.catalogId === relatedProductId)) {
      dispatch(
        Modal.actionCreators.show(Modal.modalTypes.PRODUCT_UPSELL, {
          productId,
          relatedProductId,
        })
      );
    } else {
      dispatch(Modal.actionCreators.show(Modal.modalTypes.GO_TO_CART));
    }
  },
  addRelatedProduct: (productId, relatedProductId) => async (dispatch, getState) => {
    await addItems(dispatch, getState, {
      items: {
        catalogId: relatedProductId,
        upsellReasonId: UPSELL_REASONS.RELATED_PRODUCT,
        upsellByCatalogId: productId,
      },
    });
  },
  replaceWithRelatedProduct: (productId, relatedProductId) => async (dispatch, getState) => {
    const cart = getClonedCart(getState);
    const item = cart.items.find((ci) => ci.catalogId === productId);
    if (item) {
      item.catalogId = relatedProductId;
      item.upsellReasonId = UPSELL_REASONS.RELATED_PRODUCT;
      item.upsellByCatalogId = productId;
      await saveCart(dispatch, getState, cart);
    }
  },
  addWebinarBundle: (webinarId, recordingProductId, presentGoToCart, attendee, webinarDate) => async (dispatch, getState) => {
    // called when adding a webinar bundle
    await addItems(dispatch, getState, {
      items: [
        {
          catalogId: webinarId,
          webinarDate: webinarDate
        },
        {
          catalogId: recordingProductId,
          upsellReasonId: UPSELL_REASONS.WEBINAR_BUNDLE,
          upsellByCatalogId: webinarId,
          webinarDate: webinarDate
        },
      ],
      attendee,
    });
    
    if (presentGoToCart) {
      dispatch(Modal.actionCreators.show(Modal.modalTypes.GO_TO_CART));
    }
  },
  addWebinarRecordingOnly: (recordingProductId , webinarDate , presentGoToCart) => async (dispatch, getState) => {
    // called when upselling a webinar from the cart
    await addItems(dispatch, getState, {
      items: [
        {
          catalogId: recordingProductId,
          webinarDate: webinarDate
        },
      ],
    });
    if (presentGoToCart) {
      dispatch(Modal.actionCreators.show(Modal.modalTypes.GO_TO_CART));
    }
  },
  addWebinarRecording: (webinarId, recordingProductId , webinarDate) => async (dispatch, getState) => {
    // called when upselling a webinar from the cart
    await addItems(dispatch, getState, {
      items: [
        {
          catalogId: recordingProductId,
          upsellReasonId: UPSELL_REASONS.WEBINAR_RECORDING,
          upsellByCatalogId: webinarId,
          webinarDate : webinarDate
        },
      ],
    });
  },
  updateQuantities: (items) => async (dispatch, getState) => {
    const cart = getClonedCart(getState);
    for (const item of items) {
      var idx = cart.items.findIndex((ci) => ci.cartItemId === item.cartItemId);
      if (item.quantity) {
        cart.items[idx].quantity = item.quantity;
      } else {
        cart.items.splice(idx, 1);
      }
    }
    await saveCart(dispatch, getState, cart);
  },
  removeItems: (cartItemIds) => (dispatch, getState) => {
    const modalProps = {
      title: 'Remove from cart',
      body: 'Are you sure you want to remove these items?',
      confirmText: 'Remove',
      onConfirm: async () => {
        const cart = getClonedCart(getState);
        for (let i = 0; i < cartItemIds.length; i++) {
          const cartItemId = cartItemIds[i];

          const idx = cart.items.findIndex((ci) => ci.cartItemId === cartItemId);
          if (idx > -1) {
            const item = cart.items.find((c) => c.cartItemId === cartItemId);
            dispatch(gtm.actionCreators.removeItem(item));
            cart.items.splice(idx, 1);
          }
        }

        await saveCart(dispatch, getState, cart);
      },
    };
    dispatch(Modal.actionCreators.show(Modal.modalTypes.CONFIRM_DIALOG, modalProps));
  },
  removeItem: (cartItemId) => async (dispatch, getState) => {
    const cart = getClonedCart(getState);
    const idx = cart.items.findIndex((ci) => ci.cartItemId === cartItemId);
    if (idx > -1) {
      const item = cart.items.find((c) => c.cartItemId === cartItemId);
      dispatch(gtm.actionCreators.removeItem(item));
      cart.items.splice(idx, 1);
    }
    await saveCart(dispatch, getState, cart);
  },
  confirmAndRemoveItem: (cartItemId) => (dispatch, getState) => {
    const modalProps = {
      title: 'Remove from cart',
      body: 'Are you sure you want to remove this item?',
      confirmText: 'Remove',
      onConfirm: async() => {
        await dispatch(actionCreators.removeItem(cartItemId));
      },
    };
    dispatch(Modal.actionCreators.show(Modal.modalTypes.CONFIRM_DIALOG, modalProps));
  },
  updateValues: (values) => async (dispatch, getState) => {
    const cart = { ...getClonedCart(getState), ...values };
    await saveCart(dispatch, getState, cart);
  },
  goToCart: () => (dispatch, getState) => {
    const cart = getClonedCart(getState);
    dispatch(gtm.actionCreators.reviewCart(cart));
    dispatch(push('/cart'));
  },
  goToRecommended: () => (dispatch, getState) => {
    const cart = getClonedCart(getState);
    if (cart.items.some((ci) => ci.recommendedProductId) || star12UpsellList(cart).length > 0) {
      dispatch(push('/checkout/recommended'));
    } else {
      dispatch(actionCreators.goToCheckout());
    }
  },
  goToCheckout: () => (dispatch, getState) => {
    const cart = getClonedCart(getState);
    if (
      (cart?.billingAddress && cart?.billingCity && cart?.billingCity && cart?.billingState && cart?.billingPostalCode) && 
      cart?.paymentMethod === PAYMENT_METHODS.COMPLIMENTARY.abbr &&
      cart?.attendees.some((ca) => ca.memberInfo.associateNumber > 0)
    ) {
      dispatch(actionCreators.goToVerify());
    } else {
      dispatch(gtm.actionCreators.collectInformation(cart));
      dispatch(push('/checkout'));
    }
  },
  goToVerify: () => (dispatch, getState) => {
    const cart = getClonedCart(getState);
    dispatch(gtm.actionCreators.verifyOrder(cart));
    dispatch(push('/checkout/verify'));
  },
  addAttendeeToBundle: (cartItemIds, attendee, addCompanionSeminar) => async (dispatch, getState) => {
    var firstId = cartItemIds[0];
    cartItemIds.splice(0, 1);

    // we need to add the attendee and then get the cartAttendeeId to add the rest.
    await dispatch(actionCreators.addCartItemAttendee(firstId, attendee, addCompanionSeminar));

    const cart = getClonedCart(getState);

    const newAttendee = attendee.cartAttendeeId === 0 ? cart.attendees.find((a) => a.email === attendee.email) : attendee;

    for (let i = 0; i < cartItemIds.length; i++) {
      const cartItemId = cartItemIds[i];

      await dispatch(actionCreators.addCartItemAttendee(cartItemId, newAttendee, addCompanionSeminar));
    }
  },
  addCartItemAttendee: (cartItemId, attendee, addCompanionSeminar) => async (dispatch, getState) => {
    const cart = getClonedCart(getState);
    const cartItem = cart.items.find((ci) => ci.cartItemId === cartItemId);
    const items = [
      {
        catalogId: cartItem.catalogId,
      },
    ];

    if (addCompanionSeminar && cartItem.companionSeminarId) {
      items.push({
        catalogId: cartItem.companionSeminarId,
        upsellReasonId: UPSELL_REASONS.COMPANION_SEMINAR,
        upsellByCatalogId: cartItem.catalogId,
      });
    }

    await addItems(dispatch, getState, {
      items,
      attendee,
      cart,
    });
  },
  removeCartItemAttendee: (cartItemId, cartAttendeeId) => async (dispatch, getState) => {
    const cart = getClonedCart(getState);
    const cartItem = cart.items.find((ci) => ci.cartItemId === cartItemId);
    const idx = cartItem.attendees.findIndex((cia) => cia.cartAttendeeId === cartAttendeeId);
    if (idx > -1) {
      cartItem.attendees.splice(idx, 1);
    }
    await saveCart(dispatch, getState, cart);
  },
  confirmAndRemoveCartItemAttendee: (cartItemId, cartAttendeeId) => (dispatch, getState) => {
    const modalProps = {
      title: 'Remove attendee',
      body: 'Are you sure you want to remove this attendee?',
      confirmText: 'Remove',
      onConfirm: async () => {
       await dispatch(actionCreators.removeCartItemAttendee(cartItemId, cartAttendeeId));
      },
    };
    dispatch(Modal.actionCreators.show(Modal.modalTypes.CONFIRM_DIALOG, modalProps));
  },
  confirmAndRemoveBundleAttendee: (cartItemIds, cartAttendeeId) => (dispatch, getState) => {
    const modalProps = {
      title: 'Remove attendee',
      body: 'Are you sure you want to remove this attendee?',
      confirmText: 'Remove',
      onConfirm: async () => {
        for (let i = 0; i < cartItemIds.length; i++) {
          const cartItemId = cartItemIds[i];
          await dispatch(actionCreators.removeCartItemAttendee(cartItemId, cartAttendeeId));
        }
      },
    };
    dispatch(Modal.actionCreators.show(Modal.modalTypes.CONFIRM_DIALOG, modalProps));
  },
  addOrUpdateRecommended: (products, passes) => async (dispatch, getState) => {
    const cart = getClonedCart(getState);
    let cartItemId = 0;

    for (const item of products) {
      const idx = cart.items.findIndex((ci) => ci.cartItemId === item.cartItemId);
      if (idx > -1) {
        if (item.quantity) {
          cart.items[idx].quantity = item.quantity;
          cart.items[idx].upsellReasonId = UPSELL_REASONS.RECOMMENDED_PRODUCT;
          cart.items[idx].upsellPrice = item.price;
          cart.items[idx].upsellByCatalogId = item.upsellByCatalogId;
        } else {
          cart.items.splice(idx, 1);
        }
      } else if (item.quantity) {
        cart.items.unshift({
          cartItemId,
          catalogId: item.productId,
          quantity: item.quantity,
          upsellReasonId: UPSELL_REASONS.RECOMMENDED_PRODUCT,
          upsellPrice: item.price,
          upsellByCatalogId: item.upsellByCatalogId,
        });
        --cartItemId;
      }
    }

    if (passes.length > 0) {
      let star12Professional = cart.items.find(star12ProfessionalCartItem);
      if (!star12Professional) {
        star12Professional = {
          cartItemId,
          catalogId: PASSES.STAR12_PROFESSIONAL.catalogId,
          attendees: [],
        };
        cart.items.unshift(star12Professional);
        --cartItemId;
      }

      for (const pass of passes) {
        const idx = star12Professional.attendees.findIndex((pia) => pia.cartAttendeeId === pass.cartAttendeeId);
        if (pass.selected && idx === -1) {
          star12Professional.attendees.push({
            cartItemId: star12Professional.cartItemId,
            cartAttendeeId: pass.cartAttendeeId,
            upsellReasonId: UPSELL_REASONS.STAR12,
            upsellPrice: pass.upsellPrice,
            upsellByCatalogId: pass.upsellByCatalogId,
          });
        } else if (!pass.selected && idx > -1) {
          star12Professional.attendees.splice(idx, 1);
        }
      }

      // remove cart item if no attendees
      if (star12Professional.attendees.length === 0) {
        const idx = cart.items.findIndex((ci) => ci.cartItemId === star12Professional.cartItemId);
        cart.items.splice(idx, 1);
      }
    }
    await saveCart(dispatch, getState, cart);
  },
  addRecommendedProductForSeminar: (seminarId) => async (dispatch, getState) => {
    // direct call to add a recommended product
    const rp = await loadRecommended([seminarId]);
    if (rp.length > 0) {
      await addItems(dispatch, getState, {
        items: {
          catalogId: rp[0].productId,
          upsellReasonId: UPSELL_REASONS.RECOMMENDED_PRODUCT_STANDALONE,
          upsellPrice: rp[0].price,
          upsellByCatalogId: seminarId,
        },
      });
    }
  },
  addRecordingForWebinar: (webinarId, addWebinar, attendee) => async (dispatch, getState) => {
    // direct call to add a webinar recording and optionally, the webinar
    const result = await forType(CATALOG_TYPES.WEBINAR, { id: webinarId });
    if (result.items.length > 0) {
      const webinar = result.items[0].topics[0].webinars[0];

      if (addWebinar) {
        dispatch(actionCreators.addWebinarBundle(webinarId, webinar.recordingProductId, false, attendee));
      } else {
        dispatch(actionCreators.addItem(webinar.recordingProductId, false));
      }
    }
  },
  setCompanionSeminarOffered: (cartItemId, companionSeminarId) => async (dispatch, getState) => {
    await companionSeminarOffered(cartItemId, companionSeminarId);
    const cart = getClonedCart(getState);

    const item = cart.items.find((ci) => ci.cartItemId === cartItemId);
    item.companionSeminarIdOffered = companionSeminarId;
    dispatch({ type: SET, cart });
  },
  addItemByShortCode: (shortCode, attendee) => async (dispatch, getState) => {
    // direct call to add a product
    const catalog = await forShortCode(shortCode);
    if (catalog != null) {
      await addItems(dispatch, getState, {
        items: catalog.catalogId,
        attendee,
      });
    }
  },
  addStar12Renewal: ({shortCode, renewalPrice, attendee, tiConversionPage, firstName, lastName, isRenewal}) => async (dispatch, getState) => {
    let catalog;
    if (tiConversionPage) {
      const renewalSku = changeAttendeRenewalSku(shortCode)
      catalog = await forShortCode(renewalSku);
    }else{
      catalog = await forShortCode(shortCode);
    }
    if (catalog != null) {
      await addItems(dispatch, getState, {
        items: catalog.catalogId,
        attendee,
        itemAttendee: {
          upsellReasonId: UPSELL_REASONS.STAR12_RENEWAL,
          upsellPrice: renewalPrice,
        },
        renewalPrice,
        tiRenewal: true,
        tiConversionPage,
        firstName,
        lastName,
        isRenewal
      });
      dispatch(actionCreators.goToCart());
    }
  },
};

export const reducer = (state, action) => {
  state = state || initialState;

  if (action.type === LOAD) {
    return { ...initialState, ...action.cart, ui: { ...state.ui }, loaded: true, saved: 0 };
  }

  if (action.type === SET) {
    return { ...initialState, ...action.cart, ui: { ...state.ui }, loaded: true, saved: state.saved + 1 };
  }

  if (action.type === SET_UI_KEY) {
    return { ...state, ui: { ...state.ui, [action.key]: action.value } };
  }

  return state;
};

const star12ProfessionalCartItem = (ci) =>
  ci.catalogTypeId === CATALOG_TYPES.PASS.id && ci.catalogId === PASSES.STAR12_PROFESSIONAL.catalogId;

export const star12UpsellList = (cart) => {
  const star12Professional = cart.items.find(star12ProfessionalCartItem) || { attendees: [] };
  const seminars = cart.items
    .filter(
      (ci) =>
        (ci.catalogTypeId === CATALOG_TYPES.SEMINAR.id || ci.catalogTypeId === CATALOG_TYPES.VIRTUAL_SEMINAR.id) &&
        ci.catalogPrice >= 249 &&
        ci.catalogPrice <= 399
    )
    .sort(
      (ci1, ci2) => ci2.catalogPrice - ci1.catalogPrice // note ci2 - ci1 for desc
    );

  return cart.attendees
    .filter(
      (ca) =>
        !ca.memberInfo.status && 
        !star12Professional.attendees.find((pia) => pia.cartAttendeeId === ca.cartAttendeeId && !pia.upsellReasonId) &&
        seminars.find((ci) => ci.attendees.some((cia) => cia.cartAttendeeId === ca.cartAttendeeId))
    )
    .map((ca) => {
      const seminar = seminars.find((ci) => ci.attendees.some((cia) => cia.cartAttendeeId === ca.cartAttendeeId));
      const seminarAttendee = seminar.attendees.find((cia) => cia.cartAttendeeId === ca.cartAttendeeId);

      return {
        upsellByCatalogId: seminar.catalogId,
        title: seminar.title,
        cartAttendeeId: ca.cartAttendeeId,
        price: seminarAttendee.price,
        upsellPrice: PASSES.STAR12_PROFESSIONAL.price - seminarAttendee.price,
        fullName: ca.firstName + ' ' + ca.lastName,
        selected: !!star12Professional.attendees.find((pia) => pia.cartAttendeeId === ca.cartAttendeeId),
      };
    });
};

export const useCart = () => {
  return useSelector((state) => state.cart);
};

export const useCartCount = () => {
  const cart = useCart();
  return cart.items.length;
};

export const useCompleteCartCallback = () => {
  const dispatch = useDispatch();
  const cart = useCart();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const completeCart = useCallback(
    async (recaptchaValue) => {
      setIsSubmitting(true);
      try {
        const clonedCart = cloneCart(cart);
        const order = await complete({ ...clonedCart, ReCaptchaValue: recaptchaValue });
        dispatch(gtm.actionCreators.completeOrder(cart, order.orderId));
        dispatch(push('/checkout/complete', { clientKey: order.clientKey , tiRenewal: cart.tiRenewal}));
        dispatch(actionCreators.loadCleanCart());
      } catch (e) {
        dispatch(Modal.actionCreators.showResponseErrors(e));
        throw e;
      } finally {
        setIsSubmitting(false);
      }
    },
    [dispatch, cart, setIsSubmitting]
  );

  return [cart, completeCart, isSubmitting];
};
