import axios from './handler';

export const loadRecommended = (catalogIds) => {

  return axios.get('/topic/recommended', {
    params: {
      catalogIds
    }
  });
}

