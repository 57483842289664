import React from 'react';
import { API_URL } from '../../constants';

const baseUrl = () => window.__APP_CONFIG__ && window.__APP_CONFIG__.cmsBlobUrl
  ? `${window.__APP_CONFIG__.cmsBlobUrl}`
  : `${API_URL}/content/attachment`;

export const hasAttachment = (content, which) => (content._attachments || []).indexOf(which) > -1;

export const createContentAttachmentUrl = (contentId, attachmentId) => `${baseUrl()}/${contentId}/${attachmentId || 'default'}`;

const ContentImage = ({ content = {}, contentId, attachmentId, alt, defaultImage, ...rest }) => (
  <img src={createContentAttachmentUrl(contentId || content._doc, attachmentId)} alt={alt || ''} onError={defaultImageOnError(defaultImage)} {...rest} />
);

export default ContentImage;

const defaultImageOnError = (defaultImage) => {
  return (e) => {
    if (defaultImage && e.target.tagName.toUpperCase() === 'IMG') {
      e.target.src = defaultImage;
      //e.target.src = 'http://via.placeholder.com/157x187';
      //e.target.src = star12logo;
    }
  }
};

export const ImageWithDefault = ({ defaultImage, alt, ...rest }) => <img onError={defaultImageOnError(defaultImage)} alt={alt} {...rest} />
