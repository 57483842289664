import React from 'react';
import { Link } from 'react-router-dom';
import GenericSearchBar from './GenericSearchBar';
import { BRANDS, COUNTRIES } from '../../constants';
import SPlogo from '../../img/SP_LG_SkillPath_RGB_Color_Horiz.svg';
import Duallogo from '../../img/SP_LG_SkillPath_RGB_Color_Dual.png';
import { useBrand, useCountry } from '../../store/site';
import { useCartCount } from '../../store/cart';

export const LinkLogo = ({ brand, ...rest }) =>
  <Link to="/">
    <img className="logo" src={brand.code === BRANDS.NST.code ? Duallogo : SPlogo} alt={brand.code === BRANDS.NST.code ? "National Seminars Logo" : "Skillpath Logo"} {...rest} />
  </Link>;

export default () => {

  const brand = useBrand();
  const country = useCountry();
  const cartCount = useCartCount();

  const altCountry = country === COUNTRIES.US ? COUNTRIES.CA : COUNTRIES.US;
  const altUrl = window.location.host.replace(new RegExp(`(\\.${country.tld})`), `.${altCountry.tld}`);

  return (
    <div className="SecondaryNavigation">
      <div className="container pt-1">
        <div className="row py-4">
          <div className="col-md-6 col-xl-4 text-center text-md-left">
            <LinkLogo brand={brand} id={brand.code === BRANDS.NST.code ? 'Duallogo' : 'SPlogo'} />
          </div>
          <div className="col-md-6 col-xl-8">
            <div className="row">
              <div className="col-6 text-right text-secondary pt-1 d-none d-xl-flex justify-content-end">
                {brand.code === BRANDS.SP.code && <div className="dropdown">
                  <button type="button" className="btn btn-link dropdown-toggle text-secondary p-0 d-inline" id="countryDropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{country.name}</button>
                  <div className="dropdown-menu" aria-labelledby="countryDropdown">
                    <a className="dropdown-item text-dark" href={`//${altUrl}`}>{altCountry.name}</a>
                  </div>
                </div>}
                <div className="dropdown pl-3">
                  <button type="button" className="btn btn-link dropdown-toggle text-secondary p-0 d-inline" id="signInDropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><span className="fa fa-user fa-sm pr-1"></span>
                    Sign in</button>
                  <div className="dropdown-menu" aria-labelledby="signInDropdown">
                    <a className="dropdown-item text-dark" href="https://learn.skillpath.com/learn/sign_in" rel="noopener noreferrer">SkillPath Unlimited</a>
                  </div>
                </div>
                <div className="pl-3" style={{paddingTop:'2px'}}>
                  <Link to="/cart" className="text-secondary"><i className="fas fa-shopping-cart pr-1"></i>Cart ({cartCount})</Link>
                </div>
              </div>
              <div className="col-xl-6 pt-4 pt-md-0">
                <div className="hp-search">
                  <div className="nav-item">
                    <GenericSearchBar showOutlineBtn={true} />
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
