import React from 'react';
import classNames from 'classnames';

const SocialLink = ({ which, brand, icon }) => {
  if (brand.social[which]) {
    return (
      <a href={brand.social[which]} className="pr-1" target="_blank" rel="noopener noreferrer">
        {icon ? <img src={icon} alt={which} style={{ width: '30px' }} /> : <span className={classNames('fab', 'fa-' + which, 'fa-2x')}></span>}
      </a>
    );
  }

  return null;
};

export default SocialLink;
