import qs from 'qs';

import { BRANDS, COUNTRIES } from '../constants';

export const branding = (hostname = '', pathname = '') => {

  let brand = BRANDS.SP,
    country = COUNTRIES.US;

  if (hostname.match(/(national|natsem)/gi) || pathname.match(/email-preferences/gi)) {
    brand = BRANDS.NST;
  }

  if (hostname.match(/\.ca$/gi)) {
    country = COUNTRIES.CA;
  }

  return {
    brand,
    country
  };
};

export const parse = (search) => {
  const parsed = qs.parse(search.substring(1), { strictNullHandling: true });

  // sometimes we get duplicate parameters with the double redirects
  for (const key of Object.keys(parsed)) {
    if (Array.isArray(parsed[key])) {
      parsed[key] = parsed[key][0];
    }
  }
  return parsed;
}

export const launched = (search) => {
  const parsed = parse(search);
  const launchQueryParams = {};


  if (parsed.cid) {
    launchQueryParams.clientKey = parsed.cid;
  }
  // indicates health check
  if (parsed.hc) {
    launchQueryParams.hc = parsed.hc;
  }

  if (parsed.so === 'Star12') {
    launchQueryParams.sourceOverride = 'C12';
  }

  // SP passes se or star which is rewritten as se
  // NST passes vip/ic, both should be the same
  if (parsed.se) {
    launchQueryParams.vipNumber = parsed.se;
  } else if (parsed.vip) {
    launchQueryParams.vipNumber = parsed.vip;
  }

  if (parsed.cat) {
    launchQueryParams.categoryId = parseInt(parsed.cat, 10);
  }

  if (parsed.sc) {
    launchQueryParams.shortCode = parsed.sc;
  }

  if (parsed.zip || parsed.radius || parsed.term) {
    launchQueryParams.search = {};
    if (parsed.zip) {
      launchQueryParams.search.postalCode = parsed.zip;
    }
    if (parsed.radius) {
      launchQueryParams.search.radius = parseInt(parsed.radius, 10);
    }
    if (parsed.term) {
      launchQueryParams.search.term = parsed.term;
    }
  }

  if (parsed.semnum) {
    launchQueryParams.semnum = parseInt(parsed.semnum, 10);
  }

  // CartAddPage
  if (parsed.rp) {
    launchQueryParams.rpSeminarId = parseInt(parsed.rp, 10);
  }

  if (parsed.rec) {
    launchQueryParams.recWebinarId = parseInt(parsed.rec, 10);
  }

  // PayPage
  if (parsed.invoice) {
    // very odd case where the pdf has to have the link repeated over and over
    // the user cannot click it, so they copy it and paste into address bar
    // it pastes all 10 links so invoice looks like XXXXhttps://skillpath.com/pay?invoice=XXXetc
    const idx = parsed.invoice.search(/http/);
    if (idx > -1) {
      launchQueryParams.invoice = parsed.invoice.substring(0, idx).toUpperCase();
    } else {
      launchQueryParams.invoice = parsed.invoice.toUpperCase();
    }
  }

  // PassRenewalPage and CartAddPage
  if (parsed.cust) {
    // can pass an email directly, or base64 encoded
    if (parsed.cust.indexOf('@') > -1) {
      // some test emails come from Star12 with a +, which is switched to a space by qs
      // since spaces are not allowed in email addresses, fixing this way
      // alternative is to urlencode or base64 encode the emails
      launchQueryParams.cust = parsed.cust.replace(' ', '+').toLowerCase();
    } else {
      try {
        launchQueryParams.cust = atob(parsed.cust).toLowerCase();
      } catch { }
    }
  }

  // OptInUpdatePage
  if (parsed.o) {
    launchQueryParams.optIn = parseInt(parsed.o) === 1;
  }

  // EmailPreferencePage
  if (parsed.doc) {
    launchQueryParams.doc = parsed.doc;
  }

  // OnsiteContactPage
  if (parsed.leadSource) {
    launchQueryParams.leadSource = parsed.leadSource;
  }

  const utmKeys = Object.keys(parsed).filter(x => x.startsWith("utm"));
  if (utmKeys.length > 0) {

    const gtmVariables = utmKeys
      .map(key => ({ [key]: parsed[key] }))
      .reduce((accumulator, current) =>
        ({ ...accumulator, ...current }),
        {}
      );

    launchQueryParams.gtmVariables = gtmVariables;

  }

  return {
    launchQueryParams
  };
}
