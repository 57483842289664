import { useSelector } from 'react-redux';

const LOGIN_SUCCESS_CUSTOMER_SERVICE = 'LOGIN_SUCCESS_CUSTOMER_SERVICE';

const customerServiceFromLocalStorage = localStorage.getItem('customerService');
const parsedCustomerService = customerServiceFromLocalStorage ? JSON.parse(customerServiceFromLocalStorage) : null;

const initialState = {
  username: parsedCustomerService ? parsedCustomerService?.username : null,
  isCustomerService: parsedCustomerService ? parsedCustomerService.isCustomerService : false,
  email: parsedCustomerService ? parsedCustomerService?.email : null,
};

export const loginSuccessCustomerService = (payload) => ({ type: LOGIN_SUCCESS_CUSTOMER_SERVICE, payload });

export default function customerServiceReducer(state = initialState, action) {
  switch (action.type) {
    case LOGIN_SUCCESS_CUSTOMER_SERVICE:
      return Object.assign({}, initialState, action.payload);
    default:
      return state || initialState;
  }
}

export const useCustomerService = () => {

  return useSelector(state => state.customerService);
}
