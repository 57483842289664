import axios from './handler';

export const forType = ({ searchParams = [], route, defaultSearchParams = {} } /* CATALOG_TYPE */, criteria) => {

  const params = { ...defaultSearchParams };
  for (const prop of searchParams) {
    // only add parameters that have a value
    if (criteria[prop]) {
      params[prop] = criteria[prop];
    }
  };

  return axios.get(`/${route}`, { params });
};

export const forShortCode = (shortCode) => {

  return axios.get(`/catalog/${shortCode}`);
}

export const forRoute = (catalogType /* CATALOG_TYPE */, route) => {

  return axios.get(`/${catalogType.route}/route/${route}`);
};

export const getCompanionSeminar = (catalogId) => {

  return axios.get(`/catalog/companion-seminar/${catalogId}`);
};

export const findContent = (term) => {

  return axios.get('/catalog/find', { params: { term } });
};
