import { useState, useEffect, useLayoutEffect, useCallback } from 'react';

// avoids the exhaustive-deps check error when you REALLY only want to run once
// try not to use carte blanche, your effect can probably re-run, but most likely does not
export const useEffectOnce = (effect) => {
  useEffect(effect, []);
}

export const useToggle = (initial = false) => {

  const [value, setValue] = useState(initial);

  const toggleValue = useCallback((v) => {
    if (v === true) setValue(true);
    else if (v === false) setValue(false);
    else setValue(c => !c)
  }, []);

  return [value, toggleValue];
}

export const useBookTurnLayoutEffect = (content) => {

  useLayoutEffect(() => {
    if (document.getElementById('book')) {
      window.modules.turn.init('book');

      var container = document.getElementById('book-container');
      if (container) {
        container.style.display = 'none';
      }
    }
  }, [content]);
}

export const useFormLayoutEffect = (content) => {

  useLayoutEffect(() => {

    if (document.querySelector('.es-form')) {
      window.modules.esForm.init('.es-form');
    }
    if (document.querySelector('.free-resource-form')) {
      window.modules.freeResourcesForm.init('.free-resource-form');
    }
    if (document.querySelector('.subscription-form')) {
      window.modules.subscriptionForm.init('.subscription-form');
    }

  }, [content]);
}

export const useContentOnloadLayoutEffect = (content) => {

  useLayoutEffect(() => {
    if (content && content.route && window.modules.contentOnload[content.route]) {
      window.modules.contentOnload[content.route]();
    }
  }, [content]);
}

export const useWindowSize = () => {
  const [screenWidth, setScreenWidth] = useState(0);

  useEffect(() => {
    const handleResize = () => setScreenWidth(window.innerWidth);

    // Set size at the first client-side load
    handleResize();

    window.addEventListener("resize", handleResize);

    // Remove event listener on cleanup
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return screenWidth;
};

