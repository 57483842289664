import React from 'react';
import { Link } from 'react-router-dom';
import SocialLink from './Shared/SocialLink';
import { useBrand, useHasSeminars } from '../store/site';
import CustomerLoginModal from './Shared/Modals/CustomerLoginModal';
import { useToggle } from '../utils/hooks';
import { useCustomerService } from '../store/customerService.reducer';

export default () => {

  const [isOpen, toggle] = useToggle();
  const brand = useBrand();
  const hasSeminars = useHasSeminars();
  const {isCustomerService} = useCustomerService();

  return <footer className="main-footer">
    <div className="container-fluid bg-dark">
      <div className="container py-4">
        <a href="#footerEnd" className="btn btn-primary ada-btn">Skip footer</a>
        <div className="row py-3">
          <div className="col-lg-9">
            <div className="row">
              <div className="col-sm-4 text-center text-sm-left">
                <p className="footer-link"><Link to="/category">Topics</Link></p>
                {hasSeminars && <p className="footer-link"><Link to="/seminar">In-Person Events</Link></p>}
                <p className="footer-link"><Link to="/virtual">Virtual Seminars</Link></p>
                <p className="footer-link"><Link to="/webinar">Webinars</Link></p>
                <p className="footer-link"><Link to="/product">Products</Link></p>
                <p className="footer-link"><Link to="/onsite">Group Training</Link></p>
                <p className="footer-link"><Link to="/ondemand">On-Demand Library</Link></p>
              </div>
              <div className="col-sm-4 text-center text-sm-left">
                <p className="footer-link"><Link to="/unlimited">SkillPath Unlimited</Link></p>
                <p className="footer-link"><a href="https://learn.skillpath.com/learn/sign_in" target="_blank" rel="noopener noreferrer">SkillPath Unlimited Login</a></p>
                <p className="footer-link"><Link to="/resources">Free Resources</Link></p>
                <p className="footer-link"><Link to="/blog">Our Blog</Link></p>
                <p className="footer-link"><Link to="/email-signup">Email Sign-up</Link></p>
                <p className="footer-link"><Link to="/newsletter-signup">eNewsletter Sign-up</Link></p>
              </div>
              <div className="col-sm-4 text-center text-sm-left">
                <p className="footer-link"><Link to="/contact">Contact Us</Link></p>
                <p className="footer-link"><Link to="/about">About Us</Link></p>
                <p className="footer-link"><Link to="/expect">What to Expect</Link></p>
                <p className="footer-link"><Link to="/guarantee">SkillPath Satisfaction Guarantee</Link></p>
                <p className="footer-link"><Link to="/center">Center for Professional Development</Link></p>
                <p className="footer-link"><Link to="/newsroom">Newsroom</Link></p>
                <p className="footer-link"><Link to="/careers">Careers</Link></p>
                {!isCustomerService && 
                  <p className="footer-link"><Link to="/" onClick={toggle}>Customer Service Login</Link></p>
                } 
                {isCustomerService && 
                  <p className="footer-link"><Link to="/cs-links">Customer Service Links</Link></p>
                }
              </div>
            </div>
          </div>
          <div className="col-lg-3 pt-4 pt-lg-0">
            <div className="row py-3 mb-4 bg-lighter rounded">
              <div className="col-12">
                <p className="text-white mb-0">Need help?</p>
                <p className="text-white mb-0"><a href={`tel:${brand.phone}`} className="text-white">Call us at {brand.phone}</a></p>
              </div>
            </div>
            <div className="row pt-2">
              <div className="col-12">
                <SocialLink which="facebook" brand={brand} icon={"https://content.skillpath.com/web/img/icons/SP_ICN_White_Facebook.svg"} />
                <SocialLink which="twitter" brand={brand} icon={"https://content.skillpath.com/web/img/icons/SP_ICN_White_Twitter.svg"} />
                <SocialLink which="linkedin" brand={brand} icon={"https://content.skillpath.com/web/img/icons/SP_ICN_White_LinkedIn.svg"} />
                <SocialLink which="youtube" brand={brand} icon={"https://content.skillpath.com/web/img/icons/SP_ICN_White_YouTube.svg"} />
                <SocialLink which="instagram" brand={brand} icon={"https://content.skillpath.com/web/img/icons/SP_ICN_White_Instagram.svg"} />
                <SocialLink which="glassdoor" brand={brand} icon={"https://content.skillpath.com/web/img/icons/SP_ICN_White_Glassdoor.svg"} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="container-fluid bg-darker">
      <div className="container pt-4 pb-3">
        <div className="row">
          <div className="col-sm-4">
            <p className="text-light">©{new Date().getFullYear()} {brand.name}</p>
          </div>
          <div className="col-sm-8 text-sm-right pr-0">
            <p className="footer-link"><Link to="/accreditations">Accreditations</Link><span className="px-2">|</span><Link to="/privacy">Terms of Use/Privacy Policy</Link><span className="px-2">|</span><Link to="/disclosures">Disclosures</Link></p>
          </div>
        </div>
      </div>
    </div>
    <CustomerLoginModal isOpen={isOpen} toggle={toggle}/>
    <span id="footerEnd"></span>
  </footer>
};



