import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { DateTime } from 'luxon';

const FREE_RESOURCES = 'Subscription/RegisteredFreeResources';
const ACCEPTED_GDPR = 'Subscription/AcceptedGDPR';
const SHOWN_NST_MERGE = 'Subscription/SHOWN_NST_MERGE';
const EMAIL_SIGNUP_PROMPTED = 'Subscription/EMAIL_SIGNUP_PROMPTED';
const EMAIL_SIGNUP_SUBSCRIBED = 'Subscription/EMAIL_SIGNUP_SUBSCRIBED';
const SHOWN_CORONA_MODAL = 'Subscription/SHOWN_CORONA_MODAL';
const SHOWN_S12FREETRIAL_MODAL = 'Subscription/SHOWN_S12FREETRIAL_MODAL';
const SHOWN_CANCELLATION_MODAL = 'Subscription/SHOWN_CANCELLATION_MODAL';

const initialState = {
  isRegisteredFreeResources: false,
  hasAcceptedGDPR: false,
  hasShownNSTMerge: false,
  emailSignupStatus: {}
}


export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FREE_RESOURCES:
      return {
        ...state,
        isRegisteredFreeResources: true
      }
    case ACCEPTED_GDPR:
      return {
        ...state,
        hasAcceptedGDPR: true
      }
    case SHOWN_NST_MERGE:
      return {
        ...state,
        hasShownNSTMerge: true
      }
    case EMAIL_SIGNUP_PROMPTED:
      return {
        ...state,
        emailSignupStatus: {
          ...state.emailSignupStatus,
          prompted: DateTime.local().toString()
        }
      }
    case EMAIL_SIGNUP_SUBSCRIBED:
      return {
        ...state,
        emailSignupStatus: {
          ...state.emailSignupStatus,
          subscribed: true
        }
      }
    case SHOWN_CORONA_MODAL:
      return {
        ...state,
        hasShownCoronaModal: true
      }
    case SHOWN_S12FREETRIAL_MODAL:
      return {
        ...state,
        hasShownS12FreeTrialModal: true
      }
    case SHOWN_CANCELLATION_MODAL:
      return {
        ...state,
        hasShownCancellationModal: true
      }
    default:
      return state;
  }
};

export const useFreeResources = () => {

  const dispatch = useDispatch();
  const isRegisteredFreeResources = useSelector(state => state.subscription.isRegisteredFreeResources);

  const setIsRegisteredFreeResources = useCallback(() =>
    dispatch({ type: FREE_RESOURCES })
    , [dispatch]);

  return [isRegisteredFreeResources, setIsRegisteredFreeResources];
}

export const useAcceptGDPR = () => {

  const dispatch = useDispatch();
  const hasAcceptedGDPR = useSelector(state => state.subscription.hasAcceptedGDPR || false);

  const setHasAcceptedGDPR = useCallback(() => {
    window.consentGrantedAdStorage();
    dispatch({ type: ACCEPTED_GDPR })
  }, [dispatch]);

  return [hasAcceptedGDPR, setHasAcceptedGDPR];
}

export const useShownNSTMerge = () => {

  const dispatch = useDispatch();
  const hasShownNSTMerge = useSelector(state => state.subscription.hasShownNSTMerge || false);

  const setHasShownNSTMerge = useCallback(() =>
    dispatch({ type: SHOWN_NST_MERGE })
    , [dispatch]);

  return [hasShownNSTMerge, setHasShownNSTMerge];
}

export const useEmailSignupStatus = () => {

  const dispatch = useDispatch();
  const status = useSelector(state => state.subscription.emailSignupStatus || {});

  const setPrompted = useCallback(() =>
    dispatch({ type: EMAIL_SIGNUP_PROMPTED })
    , [dispatch]);

  const setSubscribed = useCallback(() =>
    dispatch({ type: EMAIL_SIGNUP_SUBSCRIBED })
    , [dispatch]);

  return [status, setPrompted, setSubscribed];
}


export const useCoronaModalStatus = () => {

  const dispatch = useDispatch();
  const hasShownCoronaModal = useSelector(state => state.subscription.hasShownCoronaModal || false);

  const setHasShownCoronaModal = useCallback(() =>
    dispatch({ type: SHOWN_CORONA_MODAL })
    , [dispatch]);

  return [hasShownCoronaModal, setHasShownCoronaModal];
}

export const useS12FreeTrialModalStatus = () => {

  const dispatch = useDispatch();
  const hasShownS12FreeTrialModal = useSelector(state => state.subscription.hasShownS12FreeTrialModal || false);

  const setHasShownS12FreeTrialModal = useCallback(() =>
    dispatch({ type: SHOWN_S12FREETRIAL_MODAL })
    , [dispatch]);

  return [hasShownS12FreeTrialModal, setHasShownS12FreeTrialModal];
}

export const useCancellationModalStatus = () => {

  const dispatch = useDispatch();
  const hasShownCancellationModal = useSelector(state => state.subscription.hasShownCancellationModal || false);

  const setHasShownCancellationModal = useCallback(() =>
    dispatch({ type: SHOWN_CANCELLATION_MODAL })
    , [dispatch]);

  return [hasShownCancellationModal, setHasShownCancellationModal];
}
