import { externalAxios } from './handler';
import { COUNTRIES, GOOGLE_API_KEY } from '../constants';

// Strategy is to use free services that are rate limited
// https://ipstack.com/ - free tier limited to 10K/month, no-SSL, we have the professional tier with 500K/month with SSL
// https://ipapi.co/json/ - limited to 1000/day
// http://ip-api.com/ - limited to 150/minute ~ 9K/hour - NO SSL on free tier 
// Paid services  
// Our CloudCMS comes with a call that counts toward our subscription, server side - https://www.cloudcms.com/documentation/api/content-services/geolocation.html#ip-address
// https://www.maxmind.com/en/geoip2-precision-city-service - purchase in blocks
// https://ipdata.co/


export const ipstack = () => {

  return externalAxios.get('https://api.ipstack.com/check?access_key=38788e3f073856057df038ad957afc1d');
};

const ipapi = () => {

  return externalAxios.get('https://ipapi.co/json/');
};

const countryOk = code => [COUNTRIES.US.abbr, COUNTRIES.CA.abbr, 'PR', 'VI'].indexOf(code) > -1;
const roundLatLng = obj => ({ ...obj, lat: +(obj.lat.toFixed(4)), lng: +(obj.lng.toFixed(4)) });

export const fromIP = async () => {

  try {
    const _ipstack = await ipstack();
    if (_ipstack.city && countryOk(_ipstack.country_code)) {
      return roundLatLng({
        city: _ipstack.city,
        region: _ipstack.region_code,
        postalCode: _ipstack.zip,
        lat: _ipstack.latitude,
        lng: _ipstack.longitude
      })
    }

    const _ipapi = await ipapi();
    if (_ipapi.city && countryOk(_ipapi.country)) {
      return roundLatLng({
        city: _ipapi.city,
        region: _ipapi.region_code,
        postalCode: _ipapi.postal,
        lat: _ipapi.latitude,
        lng: _ipapi.longitude
      })
    }
  }
  catch (e) {}

  return null;
};

export const fromAddress = async ({ postalCode }, { abbr }) => {

  if (abbr === COUNTRIES.US.abbr) {
    if (/^00[679]/.test(postalCode)) {
      abbr = 'PR';
    } else {
      if (/^008/.test(postalCode)) {
        abbr = 'VI';
      }
    }
  }
  
  const data = await externalAxios.get(`https://maps.googleapis.com/maps/api/geocode/json?address=${postalCode}&components=country:${abbr}&key=${GOOGLE_API_KEY}`);
  const fg = fromGoogle(data.results) || { lat: null, lng: null };

  // if the user searches by city state, we do not get a postal code, calling fromLatLng does
  return (fg.lat && !fg.postalCode) ? fromLatLng(fg) : fg;
};

const getAddressComponent = (address_components, type) => {

  return address_components.find(c => c.types.indexOf(type) > -1) || {}
};

const fromGoogle = (results) => {

  if (results.length > 0 && countryOk(getAddressComponent(results[0].address_components, 'country').short_name)) {

    return {
      city: getAddressComponent(results[0].address_components, 'locality').long_name,
      region: getAddressComponent(results[0].address_components, 'administrative_area_level_1').short_name,
      postalCode: getAddressComponent(results[0].address_components, 'postal_code').short_name,
      ...roundLatLng(results[0].geometry.location)
    }
  }
  return null;
}

const fromLatLng = async ({ lat, lng }) => {

  const data = await externalAxios.get(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${GOOGLE_API_KEY}`);
  return fromGoogle(data.results);
};

const getLocation = () => {

  return new Promise((resolve, reject) => {

    navigator.geolocation.getCurrentPosition(
      (position) => resolve({
        lat: position.coords.latitude,
        lng: position.coords.longitude
      }),
      (error) => reject(error)
    );
  })
};

export const fromBrowser = async () => {

  try {

    return await fromLatLng(await getLocation());
  }
  catch (e) {
    return null;
  }
};

