import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import createExpirationTransform from 'redux-persist-transform-expire';
import { persistReducer, persistStore } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import * as Site from './site';
import * as Search from './search';
import * as Catalog from './catalog';
import * as Cart from './cart';
import * as Modal from './modal';
import * as Subscription from './subscription';
import { gtmMiddleware, reducer as gtmReducer } from './gtm';
import customerService from './customerService.reducer';

export default function configureStore(history, initialState) {

  const middleware = [
    thunk,
    routerMiddleware(history),
    gtmMiddleware
  ];

  // In development, use the browser's Redux dev tools extension if installed
  const enhancers = [];
  const isDevelopment = process.env.NODE_ENV === 'development';
  if (isDevelopment && typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION__) {
    enhancers.push(window.__REDUX_DEVTOOLS_EXTENSION__());
  }

  const expireTransform = createExpirationTransform({
    defaultState: {
    }
  })

  const reducers = {
    router: connectRouter(history),
    site: Site.reducer,
    search: persistReducer({
      key: 'search',
      storage: storage,
      whitelist: ['lat', 'lng', 'city', 'region', 'postalCode']
    }, Search.reducer),
    catalog: Catalog.reducer,
    cart: persistReducer({
      key: 'cart',
      storage: storage,
      whitelist: ['clientKey']
    }, Cart.reducer),
    modal: Modal.reducer,
    gtm: persistReducer({
      key: 'gtm',
      storage: storage,
      transforms: [expireTransform]
    }, gtmReducer),
    subscription: persistReducer({
      key: 'subscription',
      storage: storage
    }, Subscription.reducer),
    customerService
  };

  const rootReducer = combineReducers({
    ...reducers
  });

  const store = createStore(
    rootReducer,
    initialState,
    compose(applyMiddleware(...middleware), ...enhancers)
  );

  const persistor = persistStore(store);

  return {
    store,
    persistor
  }
}
