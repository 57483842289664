import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import { Modal, ModalBody } from 'reactstrap';
import LoadingSpinner from '../LoadingSpinner';

import * as Cart from '../../../store/cart';
import ProductPreview from '../../Product/ProductPreview';
import { loadRelatedProduct } from '../../../ajax/product';

const ProductUpsellModal = ({ productId, relatedProductId, isOpen, toggle }) => {

  const dispatch = useDispatch();

  const [relatedProduct, setRelatedProduct] = useState(null);

  useEffect(() => {

    const getRelatedProduct = async () => {
      try {
        setRelatedProduct(await loadRelatedProduct(productId, relatedProductId));
      } catch (error) {
        setRelatedProduct({ ...error });
      }
    }

    getRelatedProduct();
  }, [productId, relatedProductId])

  const addToCart = useCallback(() => {

    const { upsell } = relatedProduct;

    if (upsell) {
      dispatch(Cart.actionCreators.replaceWithRelatedProduct(productId, relatedProductId));
    } else {
      dispatch(Cart.actionCreators.addRelatedProduct(productId, relatedProductId));
    }

    toggle();
    dispatch(Cart.actionCreators.goToCart());
  }, [dispatch, toggle, productId, relatedProduct, relatedProductId]);

  return (
    <Modal isOpen={isOpen} toggle={toggle} centered={true} size="md">
      <ModalBody>
        {!relatedProduct && <LoadingSpinner />}
        {relatedProduct && relatedProduct.productId && <React.Fragment>
          <div className="row no-gutters">
            <h4 className="mb-3">{relatedProduct.upsell ? 'Bundle and Save!' : 'You may also like:'}</h4>
          </div>
          <ProductPreview product={relatedProduct} addProductToCart={addToCart} onLinkClick={toggle} />
        </React.Fragment>
        }
        <div className="row">
          <div className="col">
            <button className="btn btn-secondary btn-flex ml-0 mr-2" onClick={toggle}>Continue shopping</button>
            <Link className="btn btn-primary btn-flex ml-0" to="/cart" onClick={toggle}>
              Go to cart
            </Link>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
}

ProductUpsellModal.propTypes = {
  toggle: PropTypes.func.isRequired,
  productId: PropTypes.number.isRequired,
  relatedProductId: PropTypes.number.isRequired
};

export default ProductUpsellModal;
