import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { push } from 'connected-react-router';

import { fromAddress } from '../ajax/geo';
import { findContent } from '../ajax/catalog';

export const SET = 'SEARCH/SET';
const SET_FIND_RESULTS = 'SEARCH/SET_FIND_RESULTS';
export const SET_CATEGORY = 'SEARCH/SET_CATEGORY';
const SET_PILLAR = 'SEARCH/SET_PILLAR'

const initialState = {
  radius: 3000
};

const sendToLytics = (searchTerm) => {
  window.jstag.send({
    "search": searchTerm
  })
}

export const actionCreators = {
  set: (criteria, categoryId, autoExpand) => async (dispatch, getState) => {
    criteria = criteria || {};

    // autoExpand only gets passed in App.js when site is launched with a search
    // all subsequent calls strip the property
    if (autoExpand) {
      criteria.autoExpand = true;
    } else {
      delete criteria.autoExpand;
    }

    // setting search clears the catalog (see catalog reducer) and category filter, pass a categoryId to set it now, only happens during initialization in App.js
    if (categoryId) {
      criteria.categoryId = categoryId;
    } else {
      delete criteria.categoryId;
      delete criteria.pillarId;
    }

    const { search: current, site: { country } } = getState();

    if (!criteria.postalCode) {
      criteria.lat = criteria.lng = null;
    } else if ((current.postalCode && criteria.postalCode !== current.postalCode) || !criteria.lat) {
      criteria = { ...criteria, ...await fromAddress(criteria, country), locationChanged: true };
    } else if (current.radius && criteria.radius !== current.radius) {
      criteria = { ...criteria, ...await fromAddress(criteria, country), locationChanged: true };
    } else {
      delete criteria.locationChanged;
    }

    // keep the findResults if the term does not change
    criteria.findResults = current.findResults && current.term === criteria.term
      ? current.findResults
      : null;

    dispatch({ type: SET, criteria });
  },
  setCategory: (categoryId, gotoPath) => (dispatch) => {
    dispatch({ type: SET_CATEGORY, categoryId });
    if (gotoPath) {
      dispatch(push(gotoPath));
    }
  },
  setPillar: (pillarId, gotoPath) => (dispatch) => {
    dispatch({ type: SET_PILLAR, pillarId });
    if (gotoPath) {
      dispatch(push(gotoPath));
    }
  }
};

export const reducer = (state, action) => {
  state = state || initialState;

  if (action.type === SET) {

    return { ...initialState, ...action.criteria };
  }

  if (action.type === SET_FIND_RESULTS) {

    return { ...state, findResults: action.findResults };
  }

  if (action.type === SET_CATEGORY) {

    return { ...state, categoryId: action.categoryId };
  }

  if (action.type === SET_PILLAR) {

    return { ...state, pillarId: action.pillarId }
  }

  return state;
};

const searchSelector = state => state.search;

export const useSearch = () => {

  const search = useSelector(searchSelector);
  return search;
}

export const useFindResultsWatch = () => {

  const { term, launching, findResults } = useSelector(searchSelector);
  const dispatch = useDispatch();

  useEffect(() => {

    const load = async () => {
      await sendToLytics(term);
      dispatch({ type: SET_FIND_RESULTS, findResults: await findContent(term) });
    }

    if (!findResults && !launching && term) {

      load();
    }

  }, [findResults, term, launching, dispatch]);
}
