import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { Modal, ModalBody } from 'reactstrap';
import CheckmarkCircleRed from '../../../img/checkmark_circle_red.svg';

const GoToCartModal = ({ isOpen, toggle }) => {
  return (
    <Modal isOpen={isOpen} toggle={toggle} centered={true} size="md">
      <ModalBody className="p-4">
        <button type="button" className="close" onClick={toggle}>
          <span aria-hidden="true">&times;</span>
        </button>
        <img src={CheckmarkCircleRed} style={{ width: '40px', height: '40px' }} className="float-left mr-2" alt="Checkmark" />
        <h3 className="modal-title mb-4" id="cartModalLabel">Item added to your cart</h3>
        <button type="button" className="btn btn-secondary mr-2" onClick={toggle}>Continue shopping</button>
        <Link to="/cart" className="btn btn-primary" onClick={toggle}>Go to cart</Link>
      </ModalBody>
    </Modal>
  );
}

GoToCartModal.propTypes = {
  toggle: PropTypes.func.isRequired
};

export default GoToCartModal
