import React from 'react';
import GenericSearchBar from './GenericSearchBar';
import { Switch, Route } from 'react-router';
import OnsiteContactPage from '../Onsite/OnsiteContactPage';

export default () => <Switch>
  <Route path="/onsite">
    <OnsiteContactPage notFound={true} />
  </Route>
  <Route>
    <div className="container py-5">
      <div className="row">
        <div className="col">
          <h1 className="text-center pb-2"><strong>Page Not Found</strong></h1>
          <p className="text-center pb-3">Please check the URL or try using the search form below</p>
          <div className="search-form-container">
            <GenericSearchBar showOutlineBtn={true} />
          </div>
        </div>
      </div>
    </div>
  </Route>
</Switch>;
