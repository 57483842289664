import React, { useState } from 'react';
import { NavItem, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { NavLink, Link } from 'react-router-dom';
import { useCartCount } from '../../store/cart';
import { useHasSeminars } from '../../store/site';

export default ({ onToggle }) => {
  const cartCount = useCartCount();
  const [topicsDropdownOpen, setTopicsDropdownOpen] = useState(false);
  const [onsiteDropdownOpen, setOnsiteDropdownOpen] = useState(false);
  const toggleTopics = () => setTopicsDropdownOpen(prevState => !prevState);
  const toggleOnsite = () => setOnsiteDropdownOpen(prevState => !prevState);

  const hasSeminars = useHasSeminars();

  return (
    <div className="PrimaryNavigation">
      <div className="container-fluid bg-dark">
        <div className="container d-none d-xl-block">
          <ul className="nav">
            <NavItem id="header-topics">
              <Dropdown isOpen={topicsDropdownOpen} toggle={toggleTopics}>
                <DropdownToggle tag="span" className="nav-link" role="button" tabIndex="0">
                  Topics <i className="fas fa-caret-down ml-1"></i>
                </DropdownToggle>
                <DropdownMenu className="nav-dropdown-menu py-3">
                  <DropdownItem tag={Link} to="/category/administrative-support-training">Administrative Support Training</DropdownItem>
                  <DropdownItem tag={Link} to="/category/business-writing-advertising">Business Writing/Advertising</DropdownItem>
                  <DropdownItem tag={Link} to="/category/customer-service">Customer Service</DropdownItem>
                  <DropdownItem tag={Link} to="/category/finance-and-accounting">Finance and Accounting</DropdownItem>
                  <DropdownItem tag={Link} to="/category/human-resources-osha">Human Resources/OSHA</DropdownItem>
                  <DropdownItem tag={Link} to="/category/management-and-supervisory">Management and Supervisory</DropdownItem>
                  <DropdownItem tag={Link} to="/category/microsoft-excel-and-office-applications">Microsoft Excel and Office Applications</DropdownItem>
                  <DropdownItem tag={Link} to="/category/personal-development-communication">Personal Development/Communication</DropdownItem>
                  <DropdownItem tag={Link} to="/category/project-and-time-management">Project and Time Management</DropdownItem>
                  <DropdownItem tag={Link} to="/category/social-media">Social Media</DropdownItem>
                  <DropdownItem tag={Link} to="/category">View All</DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </NavItem>
            {hasSeminars &&
            <NavItem id="header-seminars">
              <NavLink to="/seminar" className="nav-link" activeClassName="active">In-Person Events</NavLink>
            </NavItem>
            }
            <NavItem id="header-virtual-seminars">
              <NavLink to="/virtual" className="nav-link" activeClassName="active">Virtual Seminars</NavLink>
            </NavItem>
            <NavItem id="header-webinars">
              <NavLink to="/webinar" className="nav-link" activeClassName="active">Webinars</NavLink>
            </NavItem>
            <NavItem id="header-resources">
              <NavLink to="/product" className="nav-link" activeClassName="active">Products</NavLink>
            </NavItem>
            <NavItem id="header-onsite">
              <Dropdown isOpen={onsiteDropdownOpen} toggle={toggleOnsite}>
                <DropdownToggle tag="span" className="nav-link" role="button" tabIndex="0">
                  Group Training <i className="fas fa-caret-down ml-1"></i>
                </DropdownToggle>
                <DropdownMenu className="nav-dropdown-menu py-3">
                  <DropdownItem tag={Link} to="/onsite">About Group Training</DropdownItem>
                  <DropdownItem tag={Link} to="/onsite/whoweare">Who We Are</DropdownItem>
                  <DropdownItem tag={Link} to="/onsite/lxp">Learning Experience Platform</DropdownItem>
                  <DropdownItem tag={Link} to="/onsite/course">Courses</DropdownItem>
                  <DropdownItem tag={Link} to="/onsite/methodology">Methodology</DropdownItem>
                  <DropdownItem tag={Link} to="/onsite/modality">Modalities</DropdownItem>
                  <DropdownItem tag={Link} to="/onsite/industry">Industries</DropdownItem>
                  <DropdownItem tag={Link} to="/onsite/reviews">Reviews</DropdownItem>
                  <DropdownItem tag={Link} to="/onsite/contact">Contact Us</DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </NavItem>
            <NavItem id="header-star12">
              <NavLink to="/unlimited" className="nav-link" activeClassName="active">SkillPath Unlimited</NavLink>
            </NavItem>
            <NavItem id="header-online">
              <NavLink to="/ondemand" className="nav-link" activeClassName="active">On-Demand</NavLink>
            </NavItem>
            <NavItem id="header-about">
              <NavLink to="/about" className="nav-link" activeClassName="active">About</NavLink>
            </NavItem>
            <NavItem id="header-contact">
              <NavLink to="/contact" className="nav-link" activeClassName="active">Contact</NavLink>
            </NavItem>
          </ul>
        </div>
        <div className="container d-xl-none px-0 px-sm-3">
          <div className="row">
            <div className="col-12">
              <button className="btn bg-transparent p-0 m-0" onClick={onToggle} aria-label="Open main navigation"><i className="fas fa-bars fa-2x text-light mt-1 pt-2"></i></button>
              <Link to="/cart" className="text-light float-right py-3"><i className="fas fa-shopping-cart pl-3 pr-1"></i>Cart ({cartCount})</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
};
