import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { DEFAULT_CATALOG_IMAGE } from '../../constants';
import ContentImage from '../Content/ContentImage';
import ProductPreviewTable from './ProductPreviewTable';

const ProductPreview = ({ product, addProductToCart, onLinkClick }) => (
  <div className="row preview">
    <div className="col-md-3 pp-left-col text-center">
      <ContentImage contentId={product.contentId} className="img-fluid border mb-3" defaultImage={DEFAULT_CATALOG_IMAGE} style={{ width: '100px' }} />
    </div>
    <div className="col pp-right-col pb-4">
      <h4><Link to={product.route} onClick={onLinkClick}>{product.title}</Link></h4>
      <ProductPreviewTable product={product} addProductToCart={addProductToCart} />
    </div>
  </div>
)

ProductPreview.propTypes = {
  product: PropTypes.shape({
    contentId: PropTypes.string.isRequired,
    shortCode: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired
  }).isRequired,
  addProductToCart: PropTypes.func, // used by the ProductUpsellModal to override behaviour
  onLinkClick: PropTypes.func // used by the ProductUpsellModal to close
};

export default ProductPreview;
