import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { branding } from './initialize';
import { COUNTRIES, CATALOG_TYPES } from '../constants';
import { DateTime } from 'luxon';

const REVIEW = 'ORDER/CART',
  VERIFY = 'ORDER/VERIFY',
  COLLECT = 'ORDER/COLLECT',
  COMPLETE = 'ORDER/COMPLETE',
  ADD = 'ORDER/ADD',
  REMOVE = 'ORDER/REMOVE',
  INITIALIZE = 'GTM/INITIALIZE',
  SET = 'SEARCH/SET';

const STAR_PRODUCTS = [
  'PASSA',
  'PASSD'
];

export const trackInitialize = (item) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(Object.assign({}, { 'event': 'initialize' }, item));
}

const getCategory = (catalogTypeId) => Object.values(CATALOG_TYPES).find(ct => ct.id === catalogTypeId).name;

const trackAddItem = (item) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    'event': 'addToCart',
    'ecommerce': {
      'add': {
        'items': [{
          'name': item.title || '',
          'id': item.catalogId,
          'price': item.price || 0,
          'category': getCategory(item.catalogTypeId)
        }]
      }
    }
  });
  window.jstag.send({
    "add_to_cart": new Date().toISOString()
  })

}

const trackRemoveItem = (item) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    'event': 'removeFromCart',
    'ecommerce': {
      'remove': {
        'items': [{
          'name': item.title || '',
          'id': item.catalogId,
          'price': item.price || 0,
          'category': getCategory(item.catalogTypeId)
        }]
      }
    }
  });
}


const trackOrderComplete = (cart, orderId) => {

  const site = branding();

  window.dataLayer = window.dataLayer || [];
  window._etmc = window._etmc || [];
  window.dataLayer.push({
    'event': 'checkout',
    'transactionId': orderId,
    'transactionAffiliation': site.brand.name,
    'transactionTotal': cart.totalAmount,
    'transactionTax': cart.taxAmount,
    'transactionShipping': cart.shippingAmount,
    'transactionProducts': cart.items.map((ci) => ({
      'sku': ci.catalogId,
      'name': ci.title,
      'category': getCategory(ci.catalogTypeId),
      'quantity': ci.quantity,
      'price': ci.price
    }))
  });

  window.dataLayer.push({
    'event': 'OrderComplete',
    'transactionAffiliation': site.brand.name,
    'transactionTotal': cart.totalAmount,
    'transactionTax': cart.taxAmount,
    'transactionShipping': cart.shippingAmount
  });

  window.dataLayer.push({
    'event': 'purchase',
    'ecommerce' : {
      'transaction_id': orderId,
      'value': cart?.totalAmount,
      'currency': "USD",
      'coupon': cart?.vipNumber,
      'tax': cart?.taxAmount,
      'shipping': cart?.shippingAmount,
      'items' : cart?.items?.map((ci) => ({
          'item_id': ci?.shortCode,
          'item_name': ci?.details,
          'coupon': ci?.vipNumber,
          'item_category': getCategory(ci?.catalogTypeId),
          'price': ci?.price,
          'quantity': ci?.quantity
      }))
    }
  })


  // we have a request to have a seperate transaction event
  // for the star 12 products (PASSA or PASSD)
  if (cart.items.some(i => STAR_PRODUCTS.includes(i.shortCode))) {
    window.dataLayer.push({
      'event': 'Star12OrderComplete',
      'transactionId': orderId,
      'transactionAffiliation': site.brand.name,
      'transactionTotal': cart.totalAmount,
      'transactionTax': cart.taxAmount,
      'transactionShipping': cart.shippingAmount,
      'transactionProducts': cart.items.filter(i => STAR_PRODUCTS.includes(i.shortCode)).map((ci) => ({
        'sku': ci.catalogId,
        'name': ci.title,
        'category': getCategory(ci.catalogTypeId),
        'quantity': ci.quantity,
        'price': ci.price
      }))
    });
  }

  window._etmc.push(["trackConversion", {
    "cart": cart.items.map((ci) => ({
      "item": getCategory(ci.catalogTypeId),
      "quantity": ci.quantity,
      "price": ci.price,
      "unique_id": ci.catalogId
    })),
    "order_number": orderId,
    "discount": cart.discountAmount,
    "shipping": cart.shippingAmount
  }, {
      "details": { "discountCode": cart.vipNumber },
      "currency_code": site.country.name === COUNTRIES.US ? "USD" : "CAD"
    }]);
  // send data to Lytics
  window.jstag.send({
    "completed_order": new Date().toISOString()
  })
}

const trackCheckoutStep = (cart, step) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    'event': 'checkout',
    'ecommerce': {
      'checkout': {
        'actionField': { 'step': step },
        'products': cart.items.map((ci) => {
          return {
            'name': ci.title,
            'id': ci.catalogId,
            'price': ci.price,
            'category': getCategory(ci.catalogTypeId),
            'quantity': ci.quantity
          }
        }),
        'order': {
          'taxAmount': cart.taxAmount,
          'billingPostalCode': cart.billingPostalCode,
          'shippingQuantity': cart.shippingQuantity,
          'shippingMethod': cart.shippingMethod,
          'paymentMethod': cart.paymentMethod,
          'creditCardType': cart.creditCardType,
          'purchaseOrderNumber': cart.purchaseOrderNumber,
          'taxExemptNumber': cart.taxExemptNumber,
          'totalAmount': cart.totalAmount,
          'discountAmount': cart.discountAmount
        },
        'customer': {
          'email': cart.email,
          "phone_number": "+1" + (cart.shippingSameAsBilling) ? cart.billingPhone : cart.shippingPhone,
          "first_name": cart.firstName,
          "last_name": cart.lastName,
          "home_address": {
            "street": (cart.shippingSameAsBilling) ? cart.billingAddress : cart.shippingAddress,
            "city": (cart.shippingSameAsBilling) ? cart.billingCity : cart.shippingCity,
            "postal_code": (cart.shippingSameAsBilling) ? cart.billingPostalCode : cart.shippingPostalCode,
            "country": cart.countryCode
          }
        }
      }
    }
  });
  window.jstag.send({
    "review_order": new Date().toISOString()
  })
}

const trackPageView = ({ location }) => {

}

const trackRadius = (criteria) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    'event': 'searchDistance',
    criteria
  });
}

//// results are stored by type, i.e. seminar, webinar, product
export const actionCreators = {
  completeOrder: (cart, orderId) => (dispatch) => {
    dispatch({ type: COMPLETE, cart, orderId })
  },
  verifyOrder: (cart) => (dispatch) => {
    dispatch({ type: VERIFY, cart })
  },
  collectInformation: (cart) => (dispatch) => {
    dispatch({ type: COLLECT, cart })
  },
  reviewCart: (cart) => (dispatch) => {
    dispatch({ type: REVIEW, cart })
  },
  addItem: (item) => (dispatch) => {
    dispatch({ type: ADD, item })
  },
  removeItem: (item) => (dispatch) => {
    dispatch({ type: REMOVE, item })
  },
  initialize: (item) => (dispatch) => {
    dispatch({ type: INITIALIZE, item })
  },
  set: (criteria) => (dispatch) => {
    dispatch({ type: SET, criteria })
  }
};

export const gtmMiddleware = store => next => action => {

  switch (action.type) {
    case REVIEW:
      trackCheckoutStep(action.cart, 1);
      break;
    case COLLECT:
      trackCheckoutStep(action.cart, 2);
      break;
    case VERIFY:
      trackCheckoutStep(action.cart, 3);
      break;
    case COMPLETE:
      trackCheckoutStep(action.cart, 4);
      trackOrderComplete(action.cart, action.orderId);
      break;
    case ADD:
      trackAddItem(action.item);
      break;
    case REMOVE:
      trackRemoveItem(action.item);
      break;
    case INITIALIZE:
      trackInitialize(action.item);
      break;
    case SET:
      trackRadius(action.criteria);
      break;
    default:
      break;
  }

  return next(action);
}

const initialState = {
  gtmVariables: {}
}


export const reducer = (state = initialState, action) => {

  switch (action.type) {
    case INITIALIZE:
      return {
        ...state,
        persistExpiresAt: DateTime.local().plus({ hours: 1 }).toISODate(),
        gtmVariables: action.item
      }
    case "@@router/LOCATION_CHANGE":
      trackPageView(action.payload);
      return state;
    default:
      return state;
  }
};


export const useGtmVariables = () => {

  const { gtmVariables } = useSelector(state => state.gtm);

  const dispatch = useDispatch();
  const setGTMVariables = useCallback(gtmVars =>
    dispatch(actionCreators.initialize(gtmVars))
  , [dispatch]);

return [gtmVariables, setGTMVariables];
}
