import React from 'react';
import * as Modal from '../../../store/modal';

import GoToCartModal from './GoToCartModal';
import ProductUpsellModal from './ProductUpsellModal';
import ConfirmDialog from './ConfirmDialog';
import AlertDialog from './AlertDialog';
import ValidationFailedModal from './ValidationFailedModal';

const MODAL_COMPONENTS = {
  [Modal.modalTypes.GO_TO_CART]: GoToCartModal,
  [Modal.modalTypes.PRODUCT_UPSELL]: ProductUpsellModal,
  [Modal.modalTypes.CONFIRM_DIALOG]: ConfirmDialog,
  [Modal.modalTypes.ALERT_DIALOG]: AlertDialog,
  [Modal.modalTypes.VALIDATION_FAILED]: ValidationFailedModal
}

export default () => {

  const [type, props, isOpen, toggle] = Modal.useModal();

  if (!type || !MODAL_COMPONENTS[type]) {
    return null;
  }

  const SpecificModal = MODAL_COMPONENTS[type]
  return <SpecificModal isOpen={isOpen} toggle={toggle} {...props} />
};
