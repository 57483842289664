import React from 'react';
import { useAcceptGDPR } from '../../store/subscription';
import { Link } from 'react-router-dom';

const GDPRPanel = () => {

  const [hasAcceptedGDPR, setHasAcceptedGDPR] = useAcceptGDPR();

  return (
    !hasAcceptedGDPR && <div id="GPDR" className="container-fluid">
      <div className="container">
        <div className="row justify-content-center text-white">
          <div className="col-md-9">
            <p>SkillPath may use cookies to help us personalize your browsing experience, eliminate the need to fill out forms multiple times and enhance your Web site experience and the ease in which we can interact with you and your past activity and purchases.<Link className="text-light text-underline ml-2" to="/privacy">Terms of Use/Privacy Policy</Link></p>
          </div>
          <div className="col-md-3 pt-2">
            <button id="ACCEPT" onClick={setHasAcceptedGDPR} className="btn btn-primary rounded mx-1 px-4">ACCEPT</button>
          </div>
        </div>
      </div>
    </div>
  )

}

export default GDPRPanel;
