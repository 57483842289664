import React from 'react';
import needsAssessment from '../../../img/needsAssessment.jpg';
import OnsiteNeedsAssessmentForm from '../../Onsite/OnsiteNeedsAssessmentForm';

const NeedsModalThanks = () => <div className="modal fade picmodal" id="needsModalThanks" tabIndex="-1" role="dialog" aria-labelledby="needsModalThanksLabel" aria-hidden="true" displayed="false">
  <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
    <div className="modal-content">
      <div className="modal-body p-0">
        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
        <div className="row" style={{ paddingRight: '15px' }}>
          <div className="col-lg-8 py-5 px-5">
            <h2 className="text-primary">Thank you!</h2>
            <p>We love meeting people like you who make professional development a top priority. We look forward to sharing our expertise with you!</p>
            <button type="submit" className="btn btn-secondary mt-2 mr-3" data-dismiss="modal">Close</button>
          </div>
          <div className="col-lg-4 bg-image d-none d-lg-inline m-0 left-angle"
            style={{ backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${needsAssessment})` }}>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>;

export default () => {

  const onSubmitted = () => {

    // this modal is triggered from cloud cms code, so hide it using the bootstrap API
    // we could use $(ref.current) and a custom toggle
    window.$('#needsModal').modal('hide');
  }

  return <>
    <NeedsModalThanks />
    <div className="modal fade picmodal" id="needsModal" tabIndex="-1" role="dialog" aria-labelledby="needsModalLabel" aria-hidden="true" displayed="false">
      <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div className="modal-content">
          <div className="modal-body p-0">
            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
            <div className="row" style={{ paddingRight: '15px' }}>
              <div className="col-lg-8 py-4 my-2 px-5">
                <h3 className="text-primary">Prepare and energize your team with the perfect learning experience. Let&rsquo;s connect!</h3>
                <p>Complete the form below for a brief 15-minute introductory call with one of our expert learning and development consultants.</p>
                <div id="needsForm">
                  <OnsiteNeedsAssessmentForm onSubmitted={onSubmitted} />
                </div>
              </div>
              <div className="col-lg-4 bg-image d-none d-lg-inline m-0 left-angle"
                style={{ backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${needsAssessment})` }}>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </>;
};
