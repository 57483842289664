import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export const useScrollToInvalidAfterSubmit = (isSubmitting, isValid) => {
  const [prevIsSubmitting, setPrevIsSubmitting] = useState(false);

  useEffect(() => {
    // if form was submitting, but now is not submitting because it is invalid
    if (prevIsSubmitting && !isSubmitting && !isValid) {
      const element = document.querySelector('input.is-invalid,select.is-invalid');
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
    setPrevIsSubmitting(isSubmitting);
  }, [isSubmitting, isValid, prevIsSubmitting]);
}

// Workaround based on https://github.com/ReactTraining/react-router/issues/394
export const scrollToHash = (hash, retryCount = 0, retryLimit = 300) => {
  if (hash !== '') {
    window.requestAnimationFrame(() => {
      const id = hash.replace('#', '');
      const element = document.getElementById(id);
      if (element) element.scrollIntoView({ behavior: 'smooth' });
      //try until DOM loads & element can be found
      else if (retryCount < retryLimit) setTimeout(scrollToHash(hash, retryCount + 1), 100);
    });
  }
}

export const useScrollToTopEffect = () => {

  const location = useLocation();
  useEffect(() => {

    window.scrollTo(0, 0);
    if (location.hash.length) {
      scrollToHash(window.location.hash);
    }
  }, [location]);
}
