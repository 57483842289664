import React , {useState} from 'react';
import PropTypes from 'prop-types';

import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

const ConfirmDialog = ({ isOpen, toggle, title, body, onConfirm, showCancelButton, cancelText, confirmText, confirmBSStyle, ...rest }) => {
  const [isLoading,setIsLoading] = useState(false);
  return (
    <Modal isOpen={isOpen} toggle={toggle} centered={true}>
      {title && <ModalHeader toggle={toggle}>{title}</ModalHeader>}
      <ModalBody>{body}</ModalBody>
      <ModalFooter>
        {showCancelButton && <Button color="link" onClick={toggle} disabled={isLoading}>{cancelText}</Button>}
        <Button color={confirmBSStyle} disabled={isLoading} style={{width : '80px'}} onClick={async () => { setIsLoading(true);await onConfirm(rest); setIsLoading(false); toggle();  }}>
          {isLoading ? <div class="spinner-border spinner-border-sm" role="status"/>: confirmText}
        </Button>
      </ModalFooter>
    </Modal>
  );
}

ConfirmDialog.propTypes = {
  toggle: PropTypes.func.isRequired,
  title: PropTypes.string,
  body: PropTypes.string.isRequired,
  onConfirm: PropTypes.func.isRequired,
  cancelText: PropTypes.string,
  confirmText: PropTypes.string,
  confirmBSStyle: PropTypes.string,
  showCancelButton: PropTypes.bool
};

ConfirmDialog.defaultProps = {
  cancelText: 'Cancel',
  confirmText: 'Confirm',
  confirmBSStyle: 'primary',
  showCancelButton: true
};

export default ConfirmDialog
