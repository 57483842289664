import axios from './handler';

export const loadRelatedProduct = (productId, relatedProductId) => {

  return axios.get(`/product/${productId}/related/${relatedProductId}`);
};

export const relatedProducts = (productId) => {

  return axios.get(`/product/${productId}/related`);
};
