import { useCallback, useEffect, useMemo } from 'react';
import { createSelector } from 'reselect';
import { push } from 'connected-react-router';
import { useSelector, useDispatch } from 'react-redux';

import { forType, getCompanionSeminar } from '../ajax/catalog';
import { CATALOG_TYPES, LIST_RESPONSE_STATUSES } from '../constants';
import { SET as SET_SEARCH, SET_CATEGORY as SET_SEARCH_CATEGORY } from './search';
import { DateTime } from 'luxon';

const SET = 'CATALOG/SET';
const SET_PRODUCT_TYPE = 'CATALOG/SET_PRODUCT_TYPE';
const SET_BUNDLE = 'CATALOG/SET_BUNDLE';
const SET_BLOG_PAGE = 'CATALOG/SET_BLOG_PAGE';
const SET_RESOURCE_TYPE = 'CATALOG/SET_RESOURCE_TYPE';
const SET_PODCAST = 'CATALOG/SET_PODCAST';

const initialState = {};

// results are stored by type, i.e. seminar, webinar, product
export const actionCreators = {
  load: (catalogType /* CATALOG_TYPE */, topicId) => async (dispatch, getState) => {

    const catalogItem = catalogType.prop || catalogType.route;


    try {

      const { search, catalog: { catalogId, page = {} } } = getState();

      const searchParams = {
        ...search,
        catalogId,
        page: page[catalogItem] || 1,
        topicId: search.autoExpand ? topicId : null
      };
      const results = await forType(catalogType, searchParams);
      dispatch({ type: SET, payload: { [catalogItem]: results } });
    } catch (e) {
      dispatch({
        type: SET, payload: {
          [catalogItem]: {
            status: LIST_RESPONSE_STATUSES.ERROR,
            items: [],
            error: e
          }
        }
      });
    }
  },
  setBlogPage: (page) => ({ type: SET_BLOG_PAGE, page }),
  setResourceType: (resourceType) => ({ type: SET_RESOURCE_TYPE, resourceType }),
  setProductType: (productTypeId) => ({ type: SET_PRODUCT_TYPE, productTypeId }),
  setBundle: (bundleId, gotoPath) => (dispatch) => {
    dispatch({ type: SET_BUNDLE, bundleId });
    if (gotoPath) {
      dispatch(push(gotoPath));
    }
  },
  setPodcast: (podcastContentId) => ({ type: SET_PODCAST, podcastContentId }),
  loadCompanionSeminar: (id) => async (dispatch, getState) => {

    const { catalog } = getState();

    const result = await getCompanionSeminar(id);
    const route = 'companion';

    dispatch({ type: SET, payload: { [route]: [result].concat(catalog[route] ?? []) } });
  }
};

export const reducer = (state = initialState, action) => {

  if (action.type === SET_SEARCH) {
    return {
      ...state,
      blog: undefined,
      page: undefined,
      seminar: action.criteria.locationChanged ? undefined : state.seminar,
      ...(state[CATALOG_TYPES.ONDEMAND.route] && {
        [CATALOG_TYPES.ONDEMAND.route]: {
          ...state[CATALOG_TYPES.ONDEMAND.route],
          bundleId: null
        }
      })
    };
  }

  if (action.type === SET_SEARCH_CATEGORY) {
    return { ...state, blog: undefined, page: undefined };
  }

  if (action.type === SET) {
    return { ...state, ...action.payload };
  }

  if (action.type === SET_PRODUCT_TYPE) {
    return {
      ...state, [CATALOG_TYPES.PRODUCT.route]: { ...state[CATALOG_TYPES.PRODUCT.route], productTypeId: action.productTypeId }
    }
  }

  if (action.type === SET_BUNDLE) {
    return {
      ...state, [CATALOG_TYPES.ONDEMAND.route]: { ...state[CATALOG_TYPES.ONDEMAND.route], bundleId: action.bundleId }
    }
  }

  if (action.type === SET_BLOG_PAGE) {
    return {
      ...state, page: { ...state.page, [CATALOG_TYPES.BLOG.route]: action.page }, [CATALOG_TYPES.BLOG.route]: undefined
    }
  }

  if (action.type === SET_RESOURCE_TYPE) {
    return {
      ...state, [CATALOG_TYPES.RESOURCE.route]: { ...state[CATALOG_TYPES.RESOURCE.route], resourceType: action.resourceType }
    }
  }

  if (action.type === SET_PODCAST) {
    return {
      ...state, [CATALOG_TYPES.PODCAST.route]: { ...state[CATALOG_TYPES.PODCAST.route], podcastContentId: action.podcastContentId }
    }
  }

  return state;
};

const catalogSelector = state => state.catalog;
const searchSelector = state => state.search;

// use when you want the catalogItem, but are not responsible for it loading
const useCatalogItemResponse = (catalogType /* CATALOG_TYPE */) => {

  const { prop, route } = catalogType;
  const { [prop || route]: catalog } = useSelector(catalogSelector);
  return catalog;
}

const useCatalogItem = (catalogType /* CATALOG_TYPE */, delay, topicId) => {

  const { prop, route } = catalogType;
  const { [prop || route]: catalog } = useSelector(catalogSelector);
  const { launching } = useSelector(searchSelector);

  const dispatch = useDispatch();
  const load = useCallback(() =>
    dispatch(actionCreators.load(catalogType, topicId))
    , [dispatch, catalogType, topicId]);

  useEffect(() => {
    // launching is set = true in index.js to keep this from firing until
    // after persist loads and App.js componentDidMount decides how to set the initial search
    // otherwise we get multiple search calls
    if (!catalog && !launching) {

      if (delay > 0) {

        setTimeout(load, delay);
      } else {

        load();
      }
    }
  }, [catalog, launching, delay, load]);

  return catalog;
}

const useCatalogItemFiltered = (catalogType /* CATALOG_TYPE */, delay, topicId) => {
  useCatalogItem(catalogType, delay, topicId);

  const selector = CATALOG_TYPE_SELECTOR_MAP.get(catalogType);

  const filtered = useSelector(selector);
  return filtered;
}

// make sure that criteria is a memoized/const object for stability
const useCatalogItemCustomFiltered = (catalogType /* CATALOG_TYPE */, criteria, delay) => {

  useCatalogItem(catalogType, delay);

  const customSelector = useMemo(() => catalogTypeCustomSelectorFactory(catalogType, CATALOG_TYPE_TO_FILTERER_MAP.get(catalogType), criteria)
    , [catalogType, criteria]);

  const filtered = useSelector(customSelector);
  return filtered;
}

const catalogTypeSelector = (catalogyTypeFilterer) => (catalog, search) => {

  //console.log('in selector', catalog, search);
  if (!catalog || (search.term && !search.findResults)) return undefined;

  if (catalog && catalog.items.length === 0) return catalog;

  if (search.term && search.findResults && search.findResults.items.length === 0) return search.findResults;

  return catalogyTypeFilterer(catalog, search);
}

const catalogTypeSelectorFactory = (catalogType, catalogyTypeFilterer) => {

  const { prop, route } = catalogType;

  const stateSelector = (state) => state.catalog[prop || route];
  const searchSelector = (state) => state.search;

  return createSelector(
    [stateSelector, searchSelector],
    catalogTypeSelector(catalogyTypeFilterer)
  );
}

const catalogTypeCustomSelectorFactory = (catalogType, catalogyTypeFilterer, criteria) => {

  const { prop, route } = catalogType;

  return createSelector(
    state => state.catalog[prop || route],
    _ => criteria,
    catalogTypeSelector(catalogyTypeFilterer)
  );
}

const categoryFilter = (categoryId) => (_) => _.categoryId === categoryId;
const categoryArrayFilter = (categoryId) => (_) => (_.categories || []).includes(categoryId);
const termFilter = (findResults) => (_) => ~findResults.items.indexOf(_.contentId);
const whenFilter = (when) => {
  const date = DateTime.fromISO(when);
  return (_) => DateTime.fromISO(_.startsAt).hasSame(date, 'month')
}

const NO_RESULTS = { items: [], status: LIST_RESPONSE_STATUSES.NO_RESULTS };
const NO_CRITERIA = { items: [], status: LIST_RESPONSE_STATUSES.NO_CRITERIA };
const CATALOG_TYPE_FILTERERS = {

  SEMINAR: ({ items, status }, search) => {

    if (!search.lat) return NO_CRITERIA;

    if (search.categoryId) {

      items = items.filter(categoryFilter(search.categoryId));
      if (items.length === 0) return NO_RESULTS;
    }

    if (search.term) {
      items = items.map(i => ({
        ...i,
        topics: i.topics.filter(termFilter(search.findResults))
      })).filter(i => i.topics.length > 0);

      if (items.length === 0) return NO_RESULTS;
    }

    if (search.when) {
      items = items.map(i => ({
        ...i,
        topics: i.topics.map(t => ({
          ...t,
          seminars: t.seminars.filter(whenFilter(search.when))
        })).filter(t => t.seminars.length > 0)
      })).filter(i => i.topics.length > 0);

      if (items.length === 0) return NO_RESULTS;
    }

    return {
      items,
      status
    }
  },
  WEBINAR: ({ items, status }, search) => {

    if (search.categoryId) {

      items = items.filter(categoryFilter(search.categoryId));
      if (items.length === 0) return NO_RESULTS;
    }

    if (search.term) {
      items = items.map(i => ({
        ...i,
        topics: i.topics.filter(termFilter(search.findResults))
      })).filter(i => i.topics.length > 0);

      if (items.length === 0) return NO_RESULTS;
    }

    if (search.when) {
      items = items.map(i => ({
        ...i,
        topics: i.topics.map(t => ({
          ...t,
          webinars: t.webinars.filter(whenFilter(search.when))
        })).filter(t => t.webinars.length > 0)
      })).filter(i => i.topics.length > 0);

      if (items.length === 0) return NO_RESULTS;
    }

    return {
      items,
      status
    }
  },
  PRODUCT: ({ items, status, productTypeId }, search) => {
    if (search.categoryId) {

      items = items.filter(categoryFilter(search.categoryId));
      if (items.length === 0) return NO_RESULTS;
    }

    if (search.term) {
      items = items.map(i => ({
        ...i,
        products: i.products.filter(termFilter(search.findResults))
      })).filter(i => i.products.length > 0);

      if (items.length === 0) return NO_RESULTS;
    }

    if (productTypeId) {
      items = items.map(i => ({
        ...i,
        products: i.products.filter(p => p.productTypeId === productTypeId)
      })).filter(i => i.products.length > 0);

      if (items.length === 0) return NO_RESULTS;
    }

    return {
      items,
      status
    }
  },
  ONDEMAND: ({ items, status, bundleId }, search) => {
    if (search.categoryId) {

      items = items.filter(categoryFilter(search.categoryId));
      if (items.length === 0) return NO_RESULTS;
    }

    if (search.term) {
      items = items.filter(i => i.title.toLowerCase().includes(search.term.toLowerCase()));
      if (items.length === 0) return NO_RESULTS;
    }

    if (bundleId) {
      items = items.filter(o => o.ondemandId === bundleId);
      if (items.length === 0) return NO_RESULTS;
    }

    return {
      items,
      status
    }
  },
  VIRTUAL_SEMINAR: ({ items, status }, search) => {

    if (search.categoryId) {

      items = items.filter(categoryFilter(search.categoryId));
      if (items.length === 0) return NO_RESULTS;
    }

    if (search.term) {
      items = items.map(i => ({
        ...i,
        topics: i.topics.filter(termFilter(search.findResults))
      })).filter(i => i.topics.length > 0);

      if (items.length === 0) return NO_RESULTS;
    }

    if (search.when) {
      items = items.map(i => ({
        ...i,
        topics: i.topics.map(t => ({
          ...t,
          virtualSeminars: t.virtualSeminars.filter(whenFilter(search.when))
        })).filter(t => t.virtualSeminars.length > 0)
      })).filter(i => i.topics.length > 0);

      if (items.length === 0) return NO_RESULTS;
    }

    return {
      items,
      status
    }
  },
  RESOURCE: ({ items, status, resourceType }, search) => {

    if (search.categoryId) {
      items = items.filter(categoryArrayFilter(search.categoryId));
      if (items.length === 0) return NO_RESULTS;
    }

    if (search.term) {
      items = items.filter(termFilter(search.findResults));
      if (items.length === 0) return NO_RESULTS;
    }

    if (resourceType) {
      items = items.filter(r => r.resourceType === resourceType);
      if (items.length === 0) return NO_RESULTS;
    }

    return {
      items,
      status
    }
  },
  NEWS: ({ items, status }, search) => {

    if (search.term) {
      items = items.filter(termFilter(search.findResults));
      if (items.length === 0) return NO_RESULTS;
    }

    return {
      items,
      status
    }
  },
  PODCAST: ({ items, status, podcastContentId }, search) => {

    if (podcastContentId) {

      items = items.filter(_ => _.contentId === podcastContentId);
      if (items.length === 0) return NO_RESULTS;
    }

    if (search.categoryId) {

      items = items.map(i => ({
        ...i,
        episodes: i.episodes.filter(categoryArrayFilter(search.categoryId))
      })).filter(i => i.episodes.length > 0);

      if (items.length === 0) return NO_RESULTS;
    }

    if (search.term) {
      items = items.map(i => ({
        ...i,
        episodes: i.episodes.filter(termFilter(search.findResults))
      })).filter(i => i.episodes.length > 0);

      if (items.length === 0) return NO_RESULTS;
    }

    return {
      items,
      status
    }
  },
  ONSITE_COURSE: ({ items, status }, search) => {

    if (search.pillarId) {
      items = items.filter(categoryFilter(search.pillarId));
      if (items.length === 0) return NO_RESULTS;
    }

    if (search.term) {
      items = items.map(i => ({
        ...i,
        courses: i.courses.filter(termFilter(search.findResults))
      })).filter(i => i.courses.length > 0);

      if (items.length === 0) return NO_RESULTS;
    }

    return {
      items,
      status
    }
  },
  ONSITE_COURSE_BYCAT: ({ items, status }, search) => {

    if (search.categoryId) {
      items = items.filter(categoryFilter(search.categoryId));
      if (items.length === 0) return NO_RESULTS;
    }

    if (search.term) {
      items = items.map(i => ({
        ...i,
        courses: i.courses.filter(termFilter(search.findResults))
      })).filter(i => i.courses.length > 0);

      if (items.length === 0) return NO_RESULTS;
    }

    return {
      items,
      status
    }
  }
}

const CATALOG_TYPE_TO_FILTERER_MAP = new Map([
  [CATALOG_TYPES.SEMINAR, CATALOG_TYPE_FILTERERS.SEMINAR],
  [CATALOG_TYPES.WEBINAR, CATALOG_TYPE_FILTERERS.WEBINAR],
  [CATALOG_TYPES.PRODUCT, CATALOG_TYPE_FILTERERS.PRODUCT],
  [CATALOG_TYPES.ONDEMAND, CATALOG_TYPE_FILTERERS.ONDEMAND],
  [CATALOG_TYPES.VIRTUAL_SEMINAR, CATALOG_TYPE_FILTERERS.VIRTUAL_SEMINAR],
  [CATALOG_TYPES.RESOURCE, CATALOG_TYPE_FILTERERS.RESOURCE],
  [CATALOG_TYPES.NEWS, CATALOG_TYPE_FILTERERS.NEWS],
  [CATALOG_TYPES.PODCAST, CATALOG_TYPE_FILTERERS.PODCAST],
  [CATALOG_TYPES.ONSITE_COURSE, CATALOG_TYPE_FILTERERS.ONSITE_COURSE],
  [CATALOG_TYPES.ONSITE_COURSE_BYCAT, CATALOG_TYPE_FILTERERS.ONSITE_COURSE_BYCAT]
]);

// ensures there is only one selector for each type
const CATALOG_TYPE_SELECTOR_MAP = new Map();
for (let [key, value] of CATALOG_TYPE_TO_FILTERER_MAP.entries()) {
  CATALOG_TYPE_SELECTOR_MAP.set(key, catalogTypeSelectorFactory(key, value));
}

export const useCompanion = () => {

  const { companion } = useSelector(catalogSelector);
  return companion;
}

export const useSeminarResponse = () => useCatalogItemResponse(CATALOG_TYPES.SEMINAR);
export const useSeminars = (delay, topicId) => useCatalogItem(CATALOG_TYPES.SEMINAR, delay, topicId);
export const useSeminarsFiltered = (delay) => useCatalogItemFiltered(CATALOG_TYPES.SEMINAR, delay);
export const useSeminarsCustomFiltered = (criteria, delay) => useCatalogItemCustomFiltered(CATALOG_TYPES.SEMINAR, criteria, delay);

export const useWebinars = (delay) => useCatalogItem(CATALOG_TYPES.WEBINAR, delay);
export const useWebinarsFiltered = (delay) => useCatalogItemFiltered(CATALOG_TYPES.WEBINAR, delay);
export const useWebinarsCustomFiltered = (criteria, delay) => useCatalogItemCustomFiltered(CATALOG_TYPES.WEBINAR, criteria, delay);

export const useProducts = (delay) => useCatalogItem(CATALOG_TYPES.PRODUCT, delay);
export const useProductsFiltered = (delay) => useCatalogItemFiltered(CATALOG_TYPES.PRODUCT, delay);
export const useProductsCustomFiltered = (criteria, delay) => useCatalogItemCustomFiltered(CATALOG_TYPES.PRODUCT, criteria, delay);

export const useOndemandResponse = () => useCatalogItemResponse(CATALOG_TYPES.ONDEMAND);
export const useOndemand = (delay) => useCatalogItem(CATALOG_TYPES.ONDEMAND, delay);
export const useOndemandFiltered = (delay) => useCatalogItemFiltered(CATALOG_TYPES.ONDEMAND, delay);

export const useVirtualSeminars = (delay) => useCatalogItem(CATALOG_TYPES.VIRTUAL_SEMINAR, delay);
export const useVirtualSeminarsFiltered = (delay) => useCatalogItemFiltered(CATALOG_TYPES.VIRTUAL_SEMINAR, delay);
export const useVirtualSeminarsCustomFiltered = (criteria, delay) => useCatalogItemCustomFiltered(CATALOG_TYPES.VIRTUAL_SEMINAR, criteria, delay);

export const useResources = (delay) => useCatalogItem(CATALOG_TYPES.RESOURCE, delay);
export const useResourcesFiltered = (delay) => useCatalogItemFiltered(CATALOG_TYPES.RESOURCE, delay);
export const useResourcesCustomFiltered = (criteria, delay) => useCatalogItemCustomFiltered(CATALOG_TYPES.RESOURCE, criteria, delay);

export const useBlog = (delay) => useCatalogItem(CATALOG_TYPES.BLOG, delay);

export const useNewsFiltered = (delay) => useCatalogItemFiltered(CATALOG_TYPES.NEWS, delay);

export const usePodcastResponse = () => useCatalogItemResponse(CATALOG_TYPES.PODCAST,);
export const usePodcasts = (delay) => useCatalogItem(CATALOG_TYPES.PODCAST, delay);
export const usePodcastsFiltered = (delay) => useCatalogItemFiltered(CATALOG_TYPES.PODCAST, delay);
export const usePodcastsCustomFiltered = (criteria, delay) => useCatalogItemCustomFiltered(CATALOG_TYPES.PODCAST, criteria, delay);

export const useOnsiteCoursesResponse = () => useCatalogItemResponse(CATALOG_TYPES.ONSITE_COURSE);
export const useOnsiteCourses = (delay) => useCatalogItem(CATALOG_TYPES.ONSITE_COURSE, delay);
export const useOnsiteCoursesFiltered = (delay) => useCatalogItemFiltered(CATALOG_TYPES.ONSITE_COURSE, delay);

export const useOnsiteCoursesByCatFiltered = (delay) => useCatalogItemFiltered(CATALOG_TYPES.ONSITE_COURSE_BYCAT, delay);
export const useOnsiteCoursesByCatCustomFiltered = (criteria, delay) => useCatalogItemCustomFiltered(CATALOG_TYPES.ONSITE_COURSE_BYCAT, criteria, delay);

export const useOnsiteIndustries = (delay) => useCatalogItem(CATALOG_TYPES.ONSITE_INDUSTRY, delay);
export const useOnsiteModalities = (delay) => useCatalogItem(CATALOG_TYPES.ONSITE_MODALITY, delay);

export const useCategories = (delay) => useCatalogItem(CATALOG_TYPES.CATEGORY, delay);

