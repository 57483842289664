import React from 'react';
import PropTypes from 'prop-types';
import ContentHeader from './ContentHeader';

const ContentPageWrapper = ({ content, children }) => {
  const contentClass = `page-content ${content._type.replace(':', '-')}`;
  return (
    <div className={contentClass}>
      {content.title && <ContentHeader content={content} />}
      {children}
    </div>
  )
};

ContentPageWrapper.propTypes = {
  content: PropTypes.shape({
    _type: PropTypes.string.isRequired,
    title: PropTypes.string,
    description: PropTypes.string    
  }).isRequired,
  children: PropTypes.element.isRequired
};

export default ContentPageWrapper;
