import React from 'react';
import PropTypes from 'prop-types';

import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

const AlertDialog = ({ isOpen, toggle, title, body, onOk, okText, okBSStyle, onClosed, ...rest }) => {
  return (
    <Modal isOpen={isOpen} toggle={toggle} centered={true} onClosed={onClosed}>
      {title && <ModalHeader toggle={toggle}>{title}</ModalHeader>}
      <ModalBody style={{ whiteSpace: 'pre-wrap' }}>{body}</ModalBody>
      <ModalFooter>
        <Button color={okBSStyle} onClick={() => { toggle(); onOk && onOk(rest); }}>{okText}</Button>
      </ModalFooter>
    </Modal>
  );
}

AlertDialog.propTypes = {
  toggle: PropTypes.func.isRequired,
  title: PropTypes.string,
  body: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element
  ]).isRequired,
  onOk: PropTypes.func,
  okText: PropTypes.string,
  okBSStyle: PropTypes.string
};

AlertDialog.defaultProps = {
  okText: 'OK',
  okBSStyle: 'primary'
};

export default AlertDialog
