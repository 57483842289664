import React from 'react';
import PropTypes from 'prop-types';
import { Form, Field, Formik } from 'formik';
import { DateTime } from 'luxon';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import * as yup from 'yup';

import { WithinOptions } from './SelectOptions';

import * as Search from '../../store/search';

export const canSearch = yup.object({});

const InnerForm = ({
  isValid,
  isSubmitting,
  setValues,
  submitForm,
  values,
  showTerm,
  showLocation,
  showDate,
  termPlaceholder
}) => (
    <Form className="search-form form-group row width-100 no-gutters">
      {showTerm &&
        <div className={classNames('col pr-0 pl-0 pr-sm-0 pr-lg-2 mb-lg-0 form-group', showLocation && showDate ? 'col-12 col-lg-6' : (showLocation || showDate ? 'col-12 col-lg-9' : 'col'))}>
          <label htmlFor="term" id="termLabel">Keyword</label>
          <Field
            type="text"
            name="term"
            id="term"
            aria-labelledby="termLabel"
            className="form-control"
            placeholder={termPlaceholder || 'Search for the skill you want to learn…'} />
        </div>
      }
      {showLocation &&
        <React.Fragment>
          <div className={classNames('col-4 col-md pl-0 pr-2 mb-lg-0 form-group')}>
            <label htmlFor="term" id="postalCodeLabel">Postal Code</label>
            <Field
              type="text"
              name="postalCode"
              aria-labelledby="postalCodeLabel"
              id="postalCode"
              className="form-control" />
          </div>
          <div className={classNames('col-5 col-md pr-0 pl-0 pr-sm-0 pr-md-2 mb-lg-0 form-group')}>
            <label htmlFor="term" id="radiusLabel">Radius</label>
            <Field
              name="radius"
              id="radius"
              aria-labelledby="radiusLabel"
              component="select"
              className={classNames("custom-select", { empty: !values.radius })}>
              <WithinOptions />
            </Field>
          </div>
        </React.Fragment>
      }
      {showDate &&
        <div className={classNames('col-auto col-lg pr-0 pl-0 mb-lg-0 mb-md-3 mb-sm-3 mb-xs-3 form-group')}>
          <label htmlFor="when" id="whenLabel">Date</label>
          <Field
            name="when"
            id="when"
            aria-labelledby="whenLabel"
            component="select"
            className={classNames("custom-select", { empty: !values.when })}>
            <option value="">All dates</option>
            {Array.from({ length: 9 }, (v, i) => DateTime.local().plus({ months: i })).map(d => <option key={d.toFormat('MMyyyy')} value={d.toFormat('yyyy-MM-01')}>{d.toFormat('LLL yyyy')}</option>)}
          </Field>
        </div>
      }

      <button className="btn btn-primary ml-2" style={{ height: '38px', marginTop:'29px' }} type="submit" disabled={!isValid || isSubmitting} aria-label="Search">
        <i className="fas fa-search"></i>
      </button>
      {(values.term || values.when) &&
        <button className="btn btn-secondary ml-2" style={{ height: '38px', marginTop:'29px' }} type="submit" onClick={() => { setValues({ ...values, term: '', when: '' }); setTimeout(submitForm, 0); }} disabled={isSubmitting} aria-label="Clear search">
          <i className="fa fa-times"></i>
        </button>
      }
    </Form>
  );

InnerForm.propTypes = {
  showTerm: PropTypes.bool,
  showLocation: PropTypes.bool,
  showDate: PropTypes.bool,
  placeholder: PropTypes.string
};

const getInitialValues = (search) => ({
  term: search.term || '',
  postalCode: search.postalCode || '',
  radius: search.radius || '',
  when: search.when || ''
});

const SearchForm = ({ onSearch, ...props }) => {

  const search = Search.useSearch();
  const dispatch = useDispatch();

  return <Formik
    enableReinitialize
    validationSchema={() => props.canSearch || canSearch}
    initialValues={getInitialValues(search)}
    isValid={(props) => (props.canSearch || canSearch).isValidSync(search)}
    onSubmit={(values, { setSubmitting }) => {
      try {

        const criteria = { ...search, ...values };
        dispatch(Search.actionCreators.set(criteria));
        onSearch && onSearch(criteria);
      }
      finally {
        setSubmitting(false);
      }
    }}
  >
    {(formProps) => <InnerForm {...formProps} {...props} />}
  </Formik>
};

export default SearchForm;
