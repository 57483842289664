import 'react-app-polyfill/ie11';
import './polyfills';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createBrowserHistory } from 'history';
import { ConnectedRouter } from 'connected-react-router';
import { PersistGate } from 'redux-persist/integration/react'

import './index.scss';
import 'pure-react-carousel/dist/react-carousel.es.css';
import 'aos/dist/aos.css';

import App from './App';
import configureStore from './store/configureStore';
import { branding, launched } from './store/initialize';

const initialState = {
  site: {
    ...branding(window.location.hostname, window.location.pathname),
    ...launched(window.location.search)
  },
  search: {
    launching: true
  }
};

const history = createBrowserHistory();
const { store, persistor } = configureStore(history, initialState);

const renderApp = Component => {
  return ReactDOM.render(
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <ConnectedRouter history={history}>
          <Component />
        </ConnectedRouter>
      </PersistGate>
    </Provider>,
    document.getElementById('root')
  );
};

renderApp(App);

if (module.hot) {
  module.hot.accept('./App', () => {
    const NextApp = require('./App').default;
    renderApp(NextApp);
  });
}
