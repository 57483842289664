import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import {  useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import withPageContent from '../withPageContent';

import StaticPage from './StaticPage';
import SearchForm from '../../Shared/SearchForm';

const StaticWithSearch = ({ content }) => {

  const dispatch = useDispatch();
  const setSearch = useCallback(() => dispatch(push('/seminar')), [dispatch]);

  return (
    <>
      <div className="container pt-5">
        <div className="col">
          <div className="row">
            <SearchForm showLocation={true} onSearch={setSearch} />
          </div>
        </div>
      </div>

      <div className="container pb-5">
        <div className="row">
          <div className="col-md-8 offset-md-2">
            <StaticPage content={content} />
          </div>
        </div>
      </div>
    </>
  );
}

StaticWithSearch.propTypes = {
  content: PropTypes.shape({
    body: PropTypes.string.isRequired,
    css: PropTypes.string
  }).isRequired
};

export default withPageContent(StaticWithSearch);
