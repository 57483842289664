import React from 'react';
import { useDispatch } from 'react-redux';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import { FieldGroup, TextField } from '../FormElements';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { loginSuccessCustomerService } from '../../../store/customerService.reducer';
import { customerServiceLogin } from '../../../ajax/customerService';

const PasswordInput = (props) => <TextField type="password" {...props} />;

const InnerForm = ({ setStatus, isSubmitting, status, values, submitForm, toggle, isOpen }) => {
  return (
    <Form noValidate>
      <Modal isOpen={isOpen} toggle={toggle} centered={true} size="md">
        <ModalBody>
          <div className="row">
            <div className="col-lg-8">
              <FieldGroup name="username" label="Email" component={TextField} />
            </div>
          </div>
          <div className="row">
            <div className="col-lg-8">
              <FieldGroup name="password" label="Password" component={PasswordInput} />
            </div>
          </div>
          {status && <div className="alert alert-danger">{status}</div>}
        </ModalBody>
        <ModalFooter>
          <button type="button" className="btn btn-secondary mr-2" onClick={toggle}>
            Cancel
          </button>
          <button
            type="button"
            className="btn btn-primary mr-2"
            disabled={isSubmitting}
            onClick={() => {
              submitForm();
            }}
          >
            Login
          </button>
        </ModalFooter>
      </Modal>
    </Form>
  );
};

const CustomerLoginModal = ({ isOpen, toggle }) => {
  const dispatch = useDispatch();
  return (
    <Formik
      enableReinitialize={false}
      initialValues={{ username: '', password: '' }}
      validationSchema={Yup.object().shape({
        username: Yup.string().email('Must be a valid email address').required('Email is required'),
        password: Yup.string().required('Password is required'),
      })}
      onSubmit={async (values, actions) => {
        try {
          await customerServiceLogin({ email: values?.username, password: values?.password })
            .then((response) => {
              const user = {
                username: response?.name,
                isCustomerService: response?.isAuthenticated,
                email: response?.username,
              };
              dispatch(loginSuccessCustomerService(user));
              localStorage.setItem('customerService', JSON.stringify(user));
              actions.resetForm();
              toggle();
            })
            .catch((err) => {
              actions.setStatus(err?.response?.data?.message);
            });
        } finally {
          actions.setSubmitting(false);
        }
      }}
    >
      {(formProps) => <InnerForm {...formProps} toggle={toggle} isOpen={isOpen} />}
    </Formik>
  );
};

export default CustomerLoginModal;
