import React, { useState, useLayoutEffect } from 'react';

import AnchorClickHandler from './AnchorClickHandler';
import { fragmentContent } from '../../ajax/content';

export default ({ html, className }) => {

  const [node, setNode] = useState();
  useLayoutEffect(() => {

    // IE11 querySelectorAll does not have forEach
    // https://stackoverflow.com/a/41142999
    node && Array.from(node.querySelectorAll('.fragment')).forEach(f => {

      const type = f.getAttribute('data-fragment-type') || 'static';
      const instance = f.getAttribute('data-fragment-instance');
      fragmentContent(type, instance).then(data => f.innerHTML = data && data.body);
    });
  }, [node, html]);

  return (
    <AnchorClickHandler render={handleAnchorClick => (
      <div
        className={className}
        dangerouslySetInnerHTML={{ __html: html }}
        onClick={handleAnchorClick}
        ref={setNode}
      />)
    } />
  )
}
