import axios from './handler';

export const saveRequestEventInArea = (values) => {

  return axios.post('/seminar/request-event-in-area', values);
}

export const landing = (seminarId, vipNumber) => {

  return axios.get(`/seminar/landing/${seminarId}`, {
    params: {
      vipNumber
    }
  });
}

export const checkInPerson = async (search) => {
  var res = await axios.get(`/seminar?lat=${search.lat}&lng=${search.lng}&radius=3000`);
  return res && res.items.length > 0;
}
