import axios, { externalAxios } from './handler';
import qs from 'qs';

const cardSecureUrl = window.__APP_CONFIG__ && window.__APP_CONFIG__.cardSecureUrl
  ? `${window.__APP_CONFIG__.cardSecureUrl}`
  : "";

export const get = (clientKey, vipNumber, sourceOverride) => {

  return axios.get(`/cart/${clientKey}`, {
    params: {
      vipNumber,
      sourceOverride
    }
  });
};

export const save = (cart) => {

  return axios.post('/cart', cart);
};

export const complete = (cart) => {

  return axios.post('/cart/complete', cart);
};

export const companionSeminarOffered = (cartItemId, companionSeminarIdOffered) => {

  return axios.post('/cart/companionSeminarOffered', { cartItemId, companionSeminarIdOffered });
};

export const tokenizeCard = async (accountNumber) => {

  if (accountNumber) {

    const data = await externalAxios.get(cardSecureUrl, {
      params: {
        action: 'CE',
        data: accountNumber
      }
    });
    return qs.parse(data).data;
  }

  return accountNumber;
};

export const validateAddress = (address) => {

  return axios.post('/cart/validateAddress', address);
};

export const memberInfo = (email) => {

  return axios.get('/cart/member', {
    params: {
      email
    }
  });
};

export const itMemberInfo = (email) => {

  return axios.get('/cart/memberFromTi', {
    params: {
      email
    }
  });
};