import { setLocale } from 'yup';
import DEFAULT_IMAGE from './img/NOIMAGE.png';

export const API_URL = '/api';
export const ENTERPRISE_URL = '//es.skillpath.com';
export const DEFAULT_CATALOG_IMAGE = DEFAULT_IMAGE;
export const GOOGLE_API_KEY = "AIzaSyAzGX0eLJxTX9z1gaM0KqJRMoY1zgqbvx0";

export const COUNTRIES = {
  US: {
    abbr: 'US',
    name: 'U.S.',
    tld: 'com',
    currencySuffix : '',
    shippingMethods: {
      'P': 'Ship via Ground Service (Within 5-7 business days)',
      'E': 'Ship via FedEx Ground (Within 3-5 business days)',
      'X': 'Ship via FedEx Air (Within 2-3 business days)'
    },
    catalogLink: '/catalog?utm_source=website&utm_medium=dcat&utm_campaign=201909&utm_content=945002'
  },
  CA: {
    abbr: 'CA',
    name: 'Canada',
    tld: 'ca',
    currencySuffix : ' USD',
    shippingMethods: {
      'V': 'Ship via Standard Shipping (Within 2-3 weeks)',
      'G': 'Ship via FedEx Ground (Within 7-10 business days)',
      'L': 'Ship via DHL Air (Within 2-3 business days)'
    }
  }
};

export const BRANDS = {
  SP: {
    code: 'SP',
    name: 'SkillPath',
    legalName: 'SkillPath Seminars',
    domain: 'skillpath',
    customerServiceEmail: 'customercare@skillpath.com',
    social: {
      instagram: 'https://www.instagram.com/skillpath/',
      youtube: 'https://www.youtube.com/user/SkillPathSeminars',
      twitter: 'https://twitter.com/skillpath',
      facebook: 'https://www.facebook.com/SkillPath/',
      linkedin: 'https://www.linkedin.com/company/skillpath-seminars',
      glassdoor: 'https://www.glassdoor.com/Overview/Working-at-SkillPath-EI_IE134411.11,20.htm',
      twitterHandle: '@SkillPath'
    },
    phone: '1-800-873-7545',
    campaignId: 2,
    defaultImage: 'https://spopsprod.blob.core.windows.net/cp-assets/default/sp_default_image.jpg',
    fbAppId: '151759474890132'
  },
  NST: {
    code: 'NST',
    name: 'National Seminars Training',
    legalName: 'National Seminars Training',
    domain: 'nationalseminarstraining',
    customerServiceEmail: 'customercare@skillpath.com',
    social: {
      instagram: 'https://www.instagram.com/skillpath/',
      youtube: 'https://www.youtube.com/user/SkillPathSeminars',
      twitter: 'https://twitter.com/skillpath',
      facebook: 'https://www.facebook.com/SkillPath/',
      linkedin: 'https://www.linkedin.com/company/skillpath-seminars',
      glassdoor: 'https://www.glassdoor.com/Overview/Working-at-SkillPath-EI_IE134411.11,20.htm',
      twitterHandle: '@SkillPath'
    },
    phone: '1-800-873-7545',
    campaignId: 2,
    defaultImage: 'https://spopsprod.blob.core.windows.net/cp-assets/default/nst_default_image.jpg'
  }
};

// NOTE id is catalogTypeId,
// BLOG, NEWS, etc. are here because they have the same call mechanism with search
export const CATALOG_TYPES = {
  SEMINAR: {
    id: 1,
    name: 'In-Person Event',
    route: 'seminar',
    searchParams: ['lat', 'lng', 'radius', 'id', 'autoExpand', 'topicId']
  },
  WEBINAR: {
    id: 2,
    name: 'Webinar',
    route: 'webinar',
    searchParams: ['id']
  },
  PRODUCT: {
    id: 3,
    name: 'Product',
    route: 'product'
  },
  PASS: {
    id: 4,
    name: 'Pass',
    route: 'pass'
  },
  ONDEMAND: {
    id: 5,
    name: 'On-Demand',
    route: 'ondemand'
  },
  VIRTUAL_SEMINAR: {
    id: 6,
    name: 'Virtual Seminar',
    route: 'virtualSeminar',
    searchParams: ['id']
  },
  BUNDLE: {
    id: 7,
    name: 'Bundle'
  },
  RESOURCE: {
    name: 'Resource',
    route: 'resource'
  },
  BLOG: {
    name: 'Post',
    route: 'blog',
    searchParams: ['term', 'categoryId', 'page', 'rowsPerPage']
  },
  NEWS: {
    name: 'News',
    route: 'news'
  },
  PODCAST: {
    name: 'Podcast',
    route: 'podcast'
  },
  ONSITE_COURSE: {
    name: 'On-Site Course',
    route: 'onsiteCourse'
  },
  ONSITE_COURSE_BYCAT: {
    name: 'On-Site Course',
    route: 'onsiteCourse',
    prop: 'onsiteCourseByCat',
    defaultSearchParams: {
      isPillar: false
    }
  },
  ONSITE_INDUSTRY: {
    name: 'On-Site Industry',
    route: 'onsiteIndustry'
  },
  ONSITE_MODALITY: {
    name: 'On-Site Modality',
    route: 'onsiteModality'
  },
  ONSITE_REP: {
    name: 'On-Site Rep',
    route: 'onsiteRep'
  },
  CATEGORY: {
    name: 'Catgory',
    route: 'category'
  }
};

export const getCompleteTypeName = (id, productId) => {
  const bigType = getCatalogTypeForId(id);

  return (bigType.id === 3) ? getProductTypeForId(productId) : bigType;
};

export const getProductTypeForId = (id) => PRODUCT_TYPES[Object.keys(PRODUCT_TYPES).find(key => PRODUCT_TYPES[key].id === id)];

export const getCatalogTypeForId = (id) => CATALOG_TYPES[Object.keys(CATALOG_TYPES).find(key => CATALOG_TYPES[key].id === id)];

export const PASSES = {
  STAR12_PROFESSIONAL: {
    catalogId: 10,
    price: 499
  },
  START12_DIGITAL: {
    catalogId: 9,
  }
};

export const PASSRA = 'PASSRA';
export const PASSRD = 'PASSRD';
export const PASSTA = 'PASSTA';
export const PASSTD = 'PASSTD';


// NOTE: this list does not include Webinar Recordings
export const CATEGORIES = [
  { categoryId: 25, name: 'Administrative Support Training' },
  { categoryId: 23, name: 'Business Writing/Advertising' },
  { categoryId: 26, name: 'Customer Service' },
  { categoryId: 19, name: 'Finance and Accounting' },
  { categoryId: 22, name: 'Human Resources/OSHA' },
  { categoryId: 20, name: 'Management and Supervisory' },
  { categoryId: 29, name: 'Microsoft Excel and Office Applications' },
  { categoryId: 24, name: 'Personal Development/Communication' },
  { categoryId: 21, name: 'Project and Time Management' },
  { categoryId: 40, name: 'Sales Training' },
  { categoryId: 41, name: 'Social Media' }
]

export const PAYMENT_METHODS = {
  CREDIT_CARD: {
    abbr: 'Cr',
    name: 'Credit Card'
  },
  INVOICE: {
    abbr: 'In',
    name: 'Invoice'
  },
  PURCHASE_ORDER: {
    abbr: 'Pu',
    name: 'Purchase Order'
  },
  COMPLIMENTARY: {
    abbr: 'Co',
    name: 'Complimentary'
  }
};

export const PAYMENT_METHODS_MAP = {
  Cr: PAYMENT_METHODS.CREDIT_CARD,
  In: PAYMENT_METHODS.INVOICE,
  Pu: PAYMENT_METHODS.PURCHASE_ORDER,
  Co: PAYMENT_METHODS.COMPLIMENTARY
};

export const PRODUCT_TYPES = {
  AUDIO: {
    id: 1,
    name: 'Audio'
  },
  BOOK: {
    id: 2,
    name: 'Books'
  },
  BOOK_SET: {
    id: 3,
    name: 'Book set'
  },
  CD: {
    id: 4,
    name: 'CD'
  },
  DVD: {
    id: 5,
    name: 'DVD'
  },
  PACKAGE: {
    id: 6,
    name: 'Package'
  },
  SOFTWARE: {
    id: 7,
    name: 'Software'
  },
  USB: {
    id: 8,
    name: 'USB'
  },
  VIDEO: {
    id: 9,
    name: 'Video'
  },
  WEBINAR: {
    id: 10,
    name: 'Webinar'
  },
  DIGITAL: {
    id: 11,
    name: 'Digital'
  }
};

export const ONDEMAND_TYPES = {
  LIBRARY_PACK: {
    id: 1,
    name: 'Library Pack'
  },
  LIBRARY: {
    id: 2,
    name: 'Library'
  },
  COURSE: {
    id: 3,
    name: 'Course'
  }
};

export const UPSELL_REASONS = {
  RELATED_PRODUCT: 1,
  WEBINAR_RECORDING: 2,
  RECOMMENDED_PRODUCT: 3,
  COMPANION_SEMINAR: 4,
  STAR12: 5,
  WEBINAR_BUNDLE: 6,
  RECOMMENDED_PRODUCT_STANDALONE: 7,
  STAR12_RENEWAL: 8
};

export const ATTENDEE_ERRORS = {
  EXPIRED_BEFORE_EVENT: 'EXPIRED_BEFORE_EVENT',
  DUPLICATE_REGISTRATION: 'DUPLICATE_REGISTRATION',
  EXISTING_MEMBERSHIP: "EXISTING_MEMBERSHIP",
  PENDING_STAR12_RENEWAL: "PENDING_STAR12_RENEWAL",
  PENDING_STAR12_PURCHASE: "PENDING_STAR12_PURCHASE"
};

export const LIST_RESPONSE_STATUSES = {
  OK: "OK",
  NO_RESULTS: "NO_RESULTS",
  NO_CRITERIA: "NO_CRITERIA",
  ERROR: "ERROR"
}

export const REGEXES = {
  POSTAL_CODE: /(^[0-9]{5}$)|(^[0-9]{5}-[0-9]{4}$)|(^[ABCEGHJ-NPRSTVXY][0-9][ABCEGHJ-NPRSTV-Z] [0-9][ABCEGHJ-NPRSTV-Z][0-9]$)/,
  PHONE: /^[0-9]{10}$/
};

export const VALIDATION_MESSAGES = {
  POSTAL_CODE: 'Enter valid Postal Code',
  PHONE: 'Enter valid number, digits only',
  NUMBER: 'Must be a number',
  AUTOMATIC_RENEWAL_SERVICE: 'You must accept terms and conditions to continue with this order.  Please call Customer Care at 1-800-873-7545  with any questions. Thank you!'
};

// for options/structure, see https://github.com/jquense/yup/blob/master/src/locale.js
setLocale({
  mixed: {
    required: 'Required'
  },
  string: {
    max: 'Must be at most ${max} characters', //eslint-disable-line no-template-curly-in-string
    matches: 'Invalid value',
    email: 'Enter a valid Email Address'
  },
  number: {
    positive: 'Must be positive number',
    integer: 'Must be a integer'
  }
});

export const DESCRIPTION = 'Our professional development seminars, conferences and on-site training are the #1 choice for training individuals, teams and organizations.'

export const newGroupPricing = {
  on: window.__APP_CONFIG__.newGroupPricing && window.__APP_CONFIG__.newGroupPricing.length > 0,
  shortCodes: window.__APP_CONFIG__.newGroupPricing
}

export function groupBy(list, keyGetter) {
  let map = new Map();
  list.forEach((item) => {
    const key = keyGetter(item);
    const collection = map.get(key);
    if (!collection) {
      map.set(key, [item]);
    } else {
      collection.push(item);
    }
  });
  return Array.from(map).map(([name, value]) => ({ name, value }));
}

export function safeAdd(a, b) {
  return Math.round((a + b) * 1e12) / 1e12;
};
