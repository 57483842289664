import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';


const AnchorClickHandler = ({ render }) =>  {

  const dispatch = useDispatch();

  // from https://github.com/STRML/react-router-component/blob/e453e24342c12a2fcfd7d7ba797be18415f9a497/lib/CaptureClicks.js#L39
  const handleAnchorClick = useCallback((e) => {
    // Ignore canceled events, modified clicks, and right clicks.
    if (e.defaultPrevented) {
      return;
    }

    if (e.metaKey || e.ctrlKey || e.shiftKey) {
      return;
    }

    if (e.button !== 0) {
      return;
    }

    // Get the <a> element.
    var el = e.target;
    while (el && el.nodeName !== 'A') {
      el = el.parentNode;
    }

    // Ignore clicks from non-a elements.
    if (!el) {
      return;
    }

    // Ignore the click if the element has a target.
    if (el.target && el.target !== '_self') {
      return;
    }

    // Ignore the click if it's a download link. (We use this method of
    // detecting the presence of the attribute for old IE versions.)
    if (el.attributes.download) {
      return;
    }

    // Ignore links that don't share a protocol and host with ours.
    if (el.protocol !== window.location.protocol || el.host !== window.location.host) {
      return;
    }

    // Ignore 'rel="external"' links.
    if (el.rel && /(?:^|\s+)external(?:\s+|$)/.test(el.rel)) {
      return;
    }

    // Prevent :focus from sticking; preventDefault() stops blur in some browsers
    el.blur();
    e.preventDefault();

    // IE does not have el.origin, IE 11 has window.location.origin
    const origin = el.origin ||
      window.location.origin ||
      window.location.protocol + "//" + window.location.hostname + (window.location.port ? ':' + window.location.port : '');

    const href = el.href.replace(origin, '');
    dispatch(push(href));
  }, [dispatch]);

  return <>
    { render(handleAnchorClick) }
  </>
}

AnchorClickHandler.propTypes = {
  render: PropTypes.func.isRequired
};

export default AnchorClickHandler;
